import { MemberInfo } from 'libs/types';
import { MyInfoBanner } from '../MyInfoBanner/MyInfoBanner';
import { MyInfoBox } from '../MyInfoBox/MyInfoBox';
import './MyInfoFlexBox.scss';
import { useTranslation } from 'react-i18next';
type MyInfoFlexBoxProps = {
  memberInfo: MemberInfo;
};

export const MyInfoFlexBox = ({ memberInfo }: MyInfoFlexBoxProps) => {
  const { t } = useTranslation();
  return (
    <div className='MyInfoFlexBox'>
      <MyInfoBanner stepImgUrl={'/images/join/회원가입.png'} stepText={t('myinfo_header')} />
      <MyInfoBox memberInfo={memberInfo} />
    </div>
  );
};
