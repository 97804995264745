import { useCallback, useEffect, useState } from 'react';
import './MainProgress.scss';
import ProgressBar from '@ramonak/react-progress-bar';
import { LectureList } from '../LectureList/LectureList';
import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import { getCookie } from 'libs/cookie';
import { Language, lectureInfo, minorTopic } from 'libs/types';
import { useHistory } from 'react-router';
import useLocationQuery from 'hooks/useLocationQuery';
import { useTranslation } from 'react-i18next';

export const MainProgress = ({ email }: { email?: string }) => {
  const { curTopicTitle = '' } = useLocationQuery();
  const [majorTopicTitle, setMajorTopicTitle] = useState('');
  const [minorTopicId, setMinorTopicId] = useState('');
  const [minorTopicList, setMinorTopicList] = useState<minorTopic[]>([]);
  const [lectureList, setLectureList] = useState<lectureInfo[]>([]);
  const history = useHistory();
  const accessToken = getCookie('accessToken');
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang') as Language;
  const majorTopics = [
    {
      title: t('lecture_survivalSwimming'),
      normal: '/images/main/생존수영_Normal.png',
      pressed: '/images/main/생존 수영_Pressed.png',
    },

    {
      title: t('lecture_eliteSwimming'),
      normal: '/images/main/엘리트 수영_Normal.png',
      pressed: '/images/main/엘리트 수영_Pressed.png',
    },

    {
      title: t('lecture_fitnessSwimming'),
      normal: '/images/main/생활체육수영_Normal.png',
      pressed: '/images/main/생활체육수영_Pressed.png',
    },
  ];

  const onLoadLectureInfo = useCallback(
    async (id: string) => {
      const res_lecture = await LifeSportsAPI.get(`/v1.0/lectures?minorTopicId=${id}`, {
        headers: {
          authorization: 'Bearer ' + accessToken,
        },
      });

      if (res_lecture.data.data.lectureList.length <= 0) return;

      return res_lecture;
    },
    [accessToken],
  );

  const onClickTopicInfo = useCallback(
    async (title: string) => {
      if (title === majorTopicTitle) return;

      history.push('/?curTopicTitle=' + title);

      setMajorTopicTitle(title);
      setMinorTopicList([]);

      console.log(lang);

      const res_major_topic = await LifeSportsAPI.get(`/v1.0/major-topics?title=${title}&lang=${lang}`, {
        headers: {
          authorization: 'Bearer ' + accessToken,
        },
      });

      if (res_major_topic.data.data.majorTopicList.length <= 0) return;

      const majorTopicId = res_major_topic.data.data.majorTopicList[0]._id;

      const res_minor_topic = await LifeSportsAPI.get(`/v1.0/minor-topics?majorTopicId=${majorTopicId}`, {
        headers: {
          authorization: 'Bearer ' + accessToken,
        },
      });

      const minorTopicList = res_minor_topic.data.data.minorTopicList;
      if (res_minor_topic.data.data.minorTopicList.length <= 0) return;

      for (let i = 0; i < minorTopicList.length; i++) {
        let minorTopic: minorTopic = minorTopicList[i];

        const res_lecture = await onLoadLectureInfo(minorTopic._id);
        const lectureList = res_lecture?.data.data.lectureList;

        if (!lectureList) break;

        let progressCount: number = 0;

        for (let j = 0; j < lectureList.length; j++) {
          const lecture: lectureInfo = lectureList[j];

          const is_completed = lecture.completedCourseList.find((completed) => completed.email === email);

          if (is_completed) {
            progressCount++;
            continue;
          }
        }

        minorTopic.progressCount = progressCount;
        minorTopic.lectureCount = lectureList.length;
      }

      setMinorTopicList(minorTopicList);
      setLectureList([]);
    },
    [accessToken, email, history, majorTopicTitle, onLoadLectureInfo, lang],
  );

  const onClickMinorTopic = async (id: string) => {
    setMinorTopicId('');

    if (id === minorTopicId) return;

    const res_lecture = await onLoadLectureInfo(id);

    if (!res_lecture) return;

    const lectureList = res_lecture?.data.data.lectureList;

    if (lectureList.length <= 0) return;

    for (let i = 0; i < lectureList.length; i++) {
      const lecture: lectureInfo = lectureList[i];

      const isCompleted = lecture.completedCourseList.find((completed) => completed.email === email);

      if (isCompleted) {
        lecture.isCompleted = true;
      } else {
        lecture.isCompleted = false;
      }
    }

    console.log(lectureList);

    setLectureList(lectureList);
    setMinorTopicId(id);
  };

  const onClickMajorTopic = useCallback(
    (title: string) => {
      onClickTopicInfo(title);
    },
    [onClickTopicInfo],
  );

  useEffect(() => {
    if (!curTopicTitle) return;

    onClickMajorTopic(curTopicTitle);
  }, [curTopicTitle, onClickMajorTopic]);

  return (
    <div className='MainProgress'>
      <div className='main-content-box'>
        <div className='main-content-main-list'>
          <ul>
            {majorTopics.map((topic, i) => (
              <li key={i} className={`major-topic-box major-topic-${i}`} onClick={() => onClickMajorTopic(topic.title)}>
                <div>
                  <img src={majorTopicTitle === topic.title ? topic.pressed : topic.normal} alt='img' />
                  <div className={majorTopicTitle === topic.title ? 'major-topic-selected' : ''}>{topic.title}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='main-content-minor-list'>
          {minorTopicList.length > 0 ? (
            minorTopicList?.map((minorTopic) => (
              <div key={minorTopic._id}>
                <div className='minor-topic-box' onClick={() => onClickMinorTopic(minorTopic._id)}>
                  <div className='minor-topic-top'>
                    <div className='minor-topic-status'>
                      {minorTopic.progressCount >= minorTopic.lectureCount ? (
                        <div className='topic-complated'>{t('lecture_completed')}</div>
                      ) : (
                        <div className='topic-in-progress'>{t('lecture_ongoing')}</div>
                      )}
                    </div>
                    <div className='minor-title'>{minorTopic.title[lang]}</div>
                  </div>
                  <div className='minor-progress-info'>
                    <ProgressBar
                      completed={minorTopic.progressCount || 0}
                      maxCompleted={minorTopic.lectureCount}
                      bgColor={'#07fdc0'}
                      isLabelVisible={false}
                      baseBgColor={'#f5f5f5'}
                      className={'minor-progress'}
                    />
                    <div className='minor-progress-value'>
                      {minorTopic.progressCount || 0}/{minorTopic.lectureCount || 0}
                    </div>
                  </div>
                </div>
                {minorTopic._id === minorTopicId && lectureList.length > 0 && (
                  <div className='main-mobile-content-progress-list'>
                    <LectureList lectureList={lectureList} lang={lang} />
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className='not-found-topics'>{t('lecture_selectType')}</div>
          )}
        </div>
      </div>
    </div>
  );
};
