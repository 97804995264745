import { getCookie } from 'libs/cookie';
import useSWR from 'swr';
import { LifeSportsAPI } from './lifesportsApi';

export default function useAuthRequest(request, { initialData, ...config } = {}) {
  return useSWR(
    request && JSON.stringify(request),
    () =>
      LifeSportsAPI(request, {
        headers: { authorization: 'Bearer ' + getCookie('accessToken') },
        withCredentials: true,
      }).then((response) => response.data),
    {
      ...config,
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        headers: {},
        data: initialData,
      },
    },
  );
}
