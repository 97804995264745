import { useState } from 'react';
import './Header.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { logout } from 'hooks/useSession';
import { Link } from 'react-router-dom';
import useLocationQuery from 'hooks/useLocationQuery';
import useMember from 'hooks/useMember';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { curTopicTitle = '' } = useLocationQuery();
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const { memberInfo } = useMember();
  const { t } = useTranslation();

  const navList = [
    { link: '/', label: t('header_myActivities') },
    { link: '/board?type=qa', label: t('header_bullentin') },
    { link: '/notice', label: t('header_notice') },
  ];

  const lectureList = [t('lecture_survivalSwimming'), t('lecture_eliteSwimming'), t('lecture_fitnessSwimming')];

  const pathname = window.location.pathname.split('/')[1];

  const onClickMyinfo = () => {
    if (isPopup) return;

    setIsPopup(true);
  };

  const onToggleMenu = () => {
    setIsMenu(!isMenu);
  };

  const curPath = window.location.pathname.split('/')[1];

  return (
    <div className='Header'>
      <div className='header-wrap'>
        <h2 className='header-title'>
          <Link to='/'>{t('header_title')}</Link>
        </h2>
        <nav className='header-nav'>
          <ul>
            {navList.map((nav) => (
              <li key={nav.label}>
                <Link to={nav.link}>{nav.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className='header-right'>
          {memberInfo?.memberType === '2' && (
            <>
              {curPath === 'cms' ? (
                <Link to={'/'}>
                  <div className='go-to-lms'>
                    <div>{t('header_lms')}</div>
                    <img src={'/images/cms/화살표.png'} alt='>' />
                  </div>
                </Link>
              ) : (
                <Link to={'/cms'}>
                  <div className='go-to-cms'>
                    <div>{t('header_cms')}</div>
                    <img src={'/images/header/화살표.png'} alt='>' />
                  </div>
                </Link>
              )}
            </>
          )}
          <div className='header-userinfo' onClick={onClickMyinfo}>
            <img src={'/images/header/내정보_1920.png'} alt='내정보' />
          </div>
        </div>
      </div>

      <div className={isMenu ? 'MobileMenu is-mobile-menu-open' : 'MobileMenu'}>
        <div className='mobile-menu-overlay' onClick={onToggleMenu} />
        <div className='mobile-menu-slide'>
          <div className='mobile-menu-content'>
            <div className='user-thumbnail'>
              <img src={'/images/header/내정보_768.png'} alt='내정보' />
            </div>
            <div className='header-btns'>
              <div className='header-myinfo'>
                <Link to='/myinfo'>{t('header_mypage')}</Link>
              </div>
              <div className='header-logout' onClick={logout}>
                <Link to='/sign-in'>Log out</Link>
              </div>
            </div>

            {curPath === 'cms' ? (
              <div className='cms-lecture-nav'>
                <ul>
                  {lectureList.map((topicTitle) => (
                    <li key={topicTitle} className={`${topicTitle === curTopicTitle ? 'is-selected-topic' : ''}`}>
                      <Link to={'/cms?curTopicTitle=' + topicTitle}>{topicTitle}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <>
                <div className={`lecture-nav ${pathname === '' && 'is-selected-nav'}`}>
                  <h3>
                    <Link to='/'>{t('header_myActivities')}</Link>
                  </h3>
                  <ul>
                    {lectureList.map((topicTitle) => (
                      <li key={topicTitle} className={`${topicTitle === curTopicTitle ? 'is-selected-topic' : ''}`}>
                        <Link to={'/?curTopicTitle=' + topicTitle}>{topicTitle}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={`board-nav ${pathname === 'board' && 'is-selected-nav'}`}>
                  <h3>
                    <Link to='/board'>{t('header_bullentin')}</Link>
                  </h3>
                  <ul>
                    <li>
                      <Link to='/board?type=qa'>{t('qna_board')}</Link>
                    </li>
                    <li>
                      <Link to='/board?type=faq'>FAQ</Link>
                    </li>
                  </ul>
                </div>
                <div className={`notice-nav ${pathname === 'notice' && 'is-selected-nav'}`}>
                  <h3>
                    <Link to='/notice'>{t('header_notice')}</Link>
                  </h3>
                </div>
              </>
            )}

            {memberInfo?.memberType === '2' && (
              <>
                {curPath === 'cms' ? (
                  <Link to={'/'}>
                    <div className='go-to-lms'>
                      <div>{t('header_lms')}</div>
                      <img src={'/images/cms/화살표.png'} alt='>' />
                    </div>
                  </Link>
                ) : (
                  <Link to={'/cms'}>
                    <div className='go-to-cms'>
                      <div>{t('header_cms')}</div>
                      <img src={'/images/header/화살표.png'} alt='>' />
                    </div>
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className='header-mobile-wrap'>
        <div className='header-menu' onClick={onToggleMenu}>
          <img src={'/images/header/메뉴.png'} alt='MENU' />
        </div>
        <div className='header-mobile-title'>
          <Link to='/'>{t('header_title')}</Link>
        </div>
      </div>

      <Popup
        modal
        open={isPopup}
        onClose={() => {
          setIsPopup(false);
        }}
      >
        <div className='header-popup'>
          <div className='header-btns'>
            <div className='header-myinfo'>
              <Link to='/myinfo'>{t('header_mypage')}</Link>
            </div>
            <div className='header-logout' onClick={logout}>
              <Link to='/sign-in'>Log out</Link>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};
