import { Language, lectureInfo } from 'libs/types';
import './CmsLectureList.scss';
import ReactPaginate from 'react-paginate';

type CmsLectureListProps = {
  lectureList: lectureInfo[];
  totalCount: number;
  limit: number;
  lang: Language;
  onClickLecturePage(event: any): void;
};

export const CmsLectureList = ({ lectureList, totalCount, limit, lang, onClickLecturePage }: CmsLectureListProps) => {
  return (
    <div className='CmsLectureBox'>
      <div className='CmsLectureList'>
        {lectureList.map((lectureInfo) => (
          <div className='cms-lecture-item' key={lectureInfo._id}>
            <div className='lecture-thumbnail'>
              {lectureInfo.thumbnail ? (
                <img src={lectureInfo.thumbnail} alt='Thumbnail' />
              ) : (
                <img src={'/images/cms/no_thumbnail.jpg'} alt='No Thumbnail' />
              )}
            </div>
            <div className='lecture-title'>{lectureInfo.title[lang]}</div>
          </div>
        ))}
      </div>
      {lectureList.length > 0 && (
        <div className='lecture-pagination'>
          <ReactPaginate
            previousLabel={<img src={'/images/cms/왼쪽 화살표.png'} alt='>' />}
            nextLabel={<img src={'/images/cms/오른쪽 화살표.png'} alt='>' />}
            marginPagesDisplayed={5}
            pageCount={Math.ceil(totalCount / limit)}
            pageClassName='page'
            pageRangeDisplayed={limit}
            onPageChange={onClickLecturePage}
          />
        </div>
      )}
    </div>
  );
};
