import './CmsMajorTopicList.scss';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

type CmsMajorTopicListProps = {
  majorTopicTitle: string;
  onClickMajorTopic(title: string): void;
};

export const CmsMajorTopicList = ({ majorTopicTitle, onClickMajorTopic }: CmsMajorTopicListProps) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');

  const majorTopics = [
    {
      title: t('lecture_survivalSwimming'),
      normal: '/images/cms/생존수영.png',
      pressed: '/images/cms/생존수영_1.png',
      normalM: '/images/cms/생존수영_Normal.png',
      pressedM: '/images/cms/생존수영_Pressed.png',
    },

    {
      title: t('lecture_eliteSwimming'),
      normal: '/images/cms/엘리트수영.png',
      pressed: '/images/cms/엘리트수영_1.png',
      normalM: '/images/cms/엘리트수영_Normal.png',
      pressedM: '/images/cms/엘리트수영_Pressed.png',
    },

    {
      title: t('lecture_fitnessSwimming'),
      normal: '/images/cms/생활체육수영.png',
      pressed: '/images/cms/생활체육수영_1.png',
      normalM: '/images/cms/생활체육수영_Normal.png',
      pressedM: '/images/cms/생활체육수영_Pressed.png',
    },
  ];

  useEffect(() => {
    if (lang === 'en-US') {
      document.getElementById('CmsMajorTopicList')!.style.height = '42vh';
    }
  }, [lang]);

  return (
    <div className='CmsMajorTopicList' id='CmsMajorTopicList'>
      <div className='cms-banner-bg' style={{ backgroundImage: `url(/images/join/bg.jpg)` }}>
        <div className='cms-banner-overlay'>
          <h3>{t('cms')}</h3>
        </div>
      </div>
      <div className='major-topic-list'>
        {majorTopics.map((topic, i) => (
          <div
            key={topic.title}
            className={`major-topic-item major-topic-${i} ${majorTopicTitle === topic.title ? 'is-seleted-topic' : ''}`}
            onClick={() => onClickMajorTopic(topic.title)}
          >
            <h3>{topic.title}</h3>
            <div className='major-topic-img'>
              <img
                className='major-topic-pc'
                src={majorTopicTitle === topic.title ? topic.pressed : topic.normal}
                alt='img'
              />
              <img
                className='major-topic-mobile'
                src={majorTopicTitle === topic.title ? topic.pressedM : topic.normalM}
                alt='img'
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
