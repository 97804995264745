import { Language, lectureInfo, minorTopic } from 'libs/types';
import { CmsLectureList } from '../CmsLectureList/CmsLectureList';
import { CmsMinorTopicList } from '../CmsMinorTopicList/CmsMinorTopicList';
import './CmsFlexBox.scss';

type CmsFlexBoxProps = {
  lectureList: lectureInfo[];
  minorTopicList: minorTopic[];
  curMinorTopicId: string;
  totalCount: number;
  limit: number;
  onClickMinorTopic(minorTopicId: string): void;
  onClickLecturePage(event: any): void;
  lang: Language;
};

export const CmsFlexBox = ({
  curMinorTopicId,
  lectureList,
  minorTopicList,
  totalCount,
  limit,
  lang,
  onClickMinorTopic,
  onClickLecturePage,
}: CmsFlexBoxProps) => {
  return (
    <div className='CmsFlexBox'>
      <CmsMinorTopicList
        curMinorTopicId={curMinorTopicId}
        minorTopicList={minorTopicList}
        onClickMinorTopic={onClickMinorTopic}
        lang={lang}
      />
      <CmsLectureList
        lectureList={lectureList}
        totalCount={totalCount}
        limit={limit}
        onClickLecturePage={onClickLecturePage}
        lang={lang}
      />
    </div>
  );
};
