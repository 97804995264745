import axios, { AxiosInstance } from 'axios';

const API_URI = 'https://lifesports-api.mrware.us/api';
const LOCAL_API_URI = 'http://localhost:8001/api';

export let LifeSportsAPI: AxiosInstance;

switch (process.env.NODE_ENV) {
  case 'production':
    LifeSportsAPI = axios.create({
      baseURL: API_URI,
      timeout: 60000,
    });
    break;
  case 'development':
    LifeSportsAPI = axios.create({
      baseURL: LOCAL_API_URI,
      timeout: 60000,
    });
    break;
  default:
    throw new Error("process.env.NODE_ENV must be either 'production' or 'development' to use this module");
}
