import { Header } from 'components/base/Header/Header';
import { Template } from 'components/base/Template/Template';
import { MainBanner } from 'components/main/MainBanner/MainBanner';
import { MainProgress } from 'components/main/MainProgress/MainProgress';
import useMember from 'hooks/useMember';
import useSession from 'hooks/useSession';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const MainPage = () => {
  const { isSession } = useSession();
  const history = useHistory();
  const { email } = useMember();

  useEffect(() => {
    if (!isSession) history.push('/sign-in');
  }, [isSession, history]);

  if (!email) return null;

  return (
    <Template header={<Header />}>
      <MainBanner />
      <MainProgress email={email} />
    </Template>
  );
};
