import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import './JoinAgree.scss';
import { useTranslation } from 'react-i18next';
import { Language } from 'libs/types';

type JoinAgreeProps = {
  stepLevel: number;
  onChangeStepLevel(level: number): void;
};

export const JoinAgree = ({ stepLevel, onChangeStepLevel }: JoinAgreeProps) => {
  const { t } = useTranslation();
  const [checkList, setCheckList] = useState<string[]>([]);
  const [agreeList, setAgreeList] = useState<string[]>([]);
  const history = useHistory();
  const lang = localStorage.getItem('lang') as Language;

  const onChangeAgree = (e: any, step: string) => {
    if (e.target.checked) {
      setCheckList([...checkList, step]);
    } else {
      setCheckList(checkList.filter((_step) => _step !== step));
    }
  };

  const onChangeAllAgree = (e: any) => {
    setCheckList(e.target.checked ? agreeList : []);
  };

  const onClickNextStep = () => {
    if (checkList.includes('one') && checkList.includes('two')) {
      onChangeStepLevel(2);
      return;
    }

    return alert(t('join_agree'));
  };

  useEffect(() => {
    setAgreeList(['one', 'two', 'three']);
  }, []);

  if (stepLevel !== 1) return null;
  return (
    <div className='JoinAgree'>
      <h2 className='join-agree-title'>{t('join_termHeader')}</h2>
      <div className='join-agree-boxes'>
        <div className='agree-contents-box'>
          {lang === 'en-US' ? <AgreeOneUS /> : <AgreeOneKR />}
          <div className='agree-check'>
            <label className='chkbox'>
              {t('agreeOne')}
              <input type='checkbox' onChange={(e) => onChangeAgree(e, 'one')} checked={checkList.includes('one')} />
              <span className='checkmark'></span>
            </label>
          </div>
        </div>

        <div className='agree-contents-box'>
          {lang === 'en-US' ? <AgreeTwoUS /> : <AgreeTwoKR />}
          <div className='agree-check'>
            <label className='chkbox'>
              {t('agreeTwo')}
              <input type='checkbox' onChange={(e) => onChangeAgree(e, 'two')} checked={checkList.includes('two')} />
              <span className='checkmark'></span>
            </label>
          </div>
        </div>

        <div className='agree-contents-box'>
          {lang === 'en-US' ? <AgreeThreeUS /> : <AgreeThreeKR />}

          <div className='agree-check'>
            <label className='chkbox'>
              {t('agreeThree')}
              <input
                type='checkbox'
                onChange={(e) => onChangeAgree(e, 'three')}
                checked={checkList.includes('three')}
              />
              <span className='checkmark'></span>
            </label>
          </div>
        </div>
      </div>
      <div className='join-all-agree'>
        <label className='chkbox'>
          {t('agreeAll')}
          <input type='checkbox' onChange={(e) => onChangeAllAgree(e)} checked={checkList.length === 3} />
          <span className='checkmark'></span>
        </label>
      </div>
      <div className='join-agree-btns'>
        <div className='agree-cancel' onClick={() => history.goBack()}>
          {t('cancel')}
        </div>
        <div className='agree-next' onClick={onClickNextStep}>
          {t('next')}
        </div>
      </div>
    </div>
  );
};

const AgreeOneKR = () => {
  return (
    <div className='agree-contents'>
      <div className='delth1'>제 1 장 총칙</div>
      <ul className='delth2'>
        <li>
          제 1 조 (목적)
          <ul className='delth3'>
            <li>
              본 약관은 글로브포인트가 운영하는 웹 사이트 (https://lifesports.mrware.us)의 제반 서비스의 이용조건 및
              절차에 관한 사항 및 기타 필요한 사항을 규정함을 목적으로 한다.
            </li>
          </ul>
        </li>
        <li>
          제 2 조 (용어의 정의)
          <ul className='delth3'>
            <li>본 약관에서 사용하는 용어는 다음과 같이 정의한다.</li>
            <li>① 회원 : 기본 회원 정보를 입력하였고, 회사와 서비스 이용계약을 체결하여 아이디를 부여받은 개인</li>
            <li>
              ② 아이디(ID) : 회원식별과 회원의 서비스 이용을 위해 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합
            </li>
            <li>③ 비밀번호(Password) : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합</li>
            <li>④ 해지 : 회사 또는 회원에 의한 이용계약의 종료</li>
          </ul>
        </li>
        <li>
          제 3 조 (약관의 공시 및 효력과 변경)
          <ul className='delth3'>
            <li>
              ① 본 약관은 회원가입 화면에 게시하여 공시하며 회사는 사정변경 및 영업상 중요한 사유가 있을 경우 약관을
              변경할 수 있으며 변경된 약관은 공지사항을 통해 공시한다.
            </li>
            <li>② 본 약관 및 차후 회사사정에 따라 변경된 약관은 이용자에게 공시함으로써 효력을 발생한다.</li>
          </ul>
        </li>
        <li>
          제 4 조 (약관 외 준칙)
          <ul className='delth3'>
            <li>
              본 약관에 명시되지 않은 사항이 전기통신기본법, 전기통신사업법, 정보통신촉진법, ‘전자상거래등에서의 소비자
              보호에 관한 법률’, ‘약관의 규제에관한법률’, ‘전자거래기본법’, ‘전자서명법’, ‘정보통신망 이용촉진등에 관한
              법률’, ‘소비자보호법’ 등 기타 관계 법령에 규정되어 있을 경우에는 그 규정을 따르도록 한다.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>제 2 장 이용계약</div>
      <ul className='delth2'>
        <li>
          제 5 조 (이용신청)
          <ul className='delth3'>
            <li>
              ① 이용신청자가 회원가입 안내에서 본 약관과 개인정보보호정책에 동의하고 등록절차(회사의 소정 양식의 가입
              신청서 작성)를 거쳐 '확인' 버튼을 누르면 이용신청을 할 수 있다.
            </li>
            <li>
              ② 이용신청자는 반드시 실명과 실제 정보를 사용해야 하며 1개의 생년월일에 대하여 1건의 이용신청을 할 수
              있다.
            </li>
            <li>
              ③ 실명이나 실제 정보를 입력하지 않은 이용자는 법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수
              있다.
            </li>
          </ul>
        </li>
        <li>
          제 6 조 (이용신청의 승낙)
          <ul className='delth3'>
            <li>
              ① 회사는 제5조에 따른 이용신청자에 대하여 제2항 및 제3항의 경우를 예외로 하여 서비스 이용을 승낙한다.
            </li>
            <li>
              ② 회사는 아래 사항에 해당하는 경우에 그 제한사유가 해소될 때까지 승낙을 유보할 수 있다.
              <br />
              가. 서비스 관련 설비에 여유가 없는 경우
              <br />
              나. 기술상 지장이 있는 경우
              <br />
              다. 기타 회사 사정상 필요하다고 인정되는 경우
            </li>
            <li>
              ③ 회사는 아래 사항에 해당하는 경우에 승낙을 하지 않을 수 있다.
              <br />
              가. 다른 사람의 명의를 사용하여 신청한 경우
              <br />
              나. 이용자 정보를 허위로 기재하여 신청한 경우
              <br />
              다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
              <br />
              라. 기타 회사가 정한 이용신청 요건이 미비한 경우
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>제 3 장 계약 당사자의 의무</div>
      <ul className='delth2'>
        <li>
          제 7 조 (회사의 의무)
          <ul className='delth3'>
            <li>① 회사는 사이트를 안정적이고 지속적으로 운영할 의무가 있다.</li>
            <li>
              ② 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 즉시 처리해야 한다. 단, 즉시
              처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 공지사항 또는 전자우편을 통해 통보해야 한다.
            </li>
            <li>
              ③ 제1항의 경우 수사상의 목적으로 관계기관 및 정보통신윤리위원회의 요청이 있거나 영장 제시가 있는 경우,
              기타 관계 법령에 의한 경우는 예외로 한다.
            </li>
          </ul>
        </li>
        <li>
          제 8 조 (이용자의 의무)
          <ul className='delth3'>
            <li>
              ① 이용자는 본 약관 및 회사의 공지사항, 사이트 이용안내 등을 숙지하고 준수해야 하며 기타 회사의 업무에
              방해되는 행위를 해서는 안된다.
            </li>
            <li>② 이용자는 회사의 사전 승인 없이 본 사이트를 이용해 어떠한 영리행위도 할 수 없다.</li>
            <li>
              ③ 이용자는 본 사이트를 통해 얻는 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판, 방송 및 기타의
              방법으로 사용하거나 이를 타인에게 제공할 수 없다.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>제 4 장 서비스의 제공 및 이용</div>
      <ul className='delth2'>
        <li>
          제 9 조 (서비스 이용)
          <ul className='delth3'>
            <li>① 이용자는 본 약관의 규정된 사항을 준수해 사이트를 이용한다.</li>
            <li>
              ② 본 약관에 명시되지 않은 서비스 이용에 관한 사항은 회사가 정해 '공지사항'에 게시하거나 또는 별도로
              공지하는 내용에 따른다.
            </li>
          </ul>
        </li>
        <li>
          제 10 조 (정보의 제공)
          <ul className='delth3'>
            <li>
              회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대하여 전자메일이나 서신우편 등의 방법으로
              회원에게 정보를 제공할 수 있다.
            </li>
          </ul>
        </li>
        <li>
          제 11 조 (광고게재)
          <ul className='delth3'>
            <li>①회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있다.</li>
            <li>
              ②회사는 사이트에 게재되어 있는 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는
              모든 손실 또는 손해에 대해 책임을 지지 않는다.
            </li>
          </ul>
        </li>
        <li>
          제 12 조 (서비스 이용의 제한)
          <ul className='delth3'>
            <li>본 사이트 이용 및 행위가 다음 각 항에 해당하는 경우 회사는 해당 이용자의 이용을 제한할 수 있다.</li>
            <li>① 공공질서 및 미풍양속, 기타 사회질서를 해하는 경우</li>
            <li>②범죄행위를 목적으로 하거나 기타 범죄행위와 관련된다고 객관적으로 인정되는 경우</li>
            <li>③타인의 명예를 손상시키거나 타인의 서비스 이용을 현저히 저해하는 경우</li>
            <li>④타인의 의사에 반하는 내용이나 광고성 정보 등을 지속적으로 전송하는 경우</li>
            <li>⑤해킹 및 컴퓨터 바이러스 유포 등으로 서비스의 건전한 운영을 저해하는 경우</li>
            <li>
              ⑥다른 이용자 또는 제3자의 지적재산권을 침해하거나 지적재산권자가 지적 재산권의 침해를 주장할 수 있다고
              판단되는 경우
            </li>
            <li>⑦타인의 아이디 및 비밀번호를 도용한 경우</li>
            <li>⑧기타 관계 법령에 위배되는 경우 및 회사가 이용자로서 부적당하다고 판단한 경우</li>
          </ul>
        </li>
        <li>
          제 13 조 (서비스 제공의 중지)
          <ul className='delth3'>
            <li>회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부의 제공을 중지할 수 있다.</li>
            <li>① 전기통신사업법 상에 규정된 기간통신 사업자 또는 인터넷 망 사업자가 서비스를 중지했을 경우</li>
            <li>② 정전으로 서비스 제공이 불가능할 경우</li>
            <li>③ 설비의 이전, 보수 또는 공사로 인해 부득이한 경우</li>
            <li>④ 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 어려운 경우</li>
            <li>⑤ 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우</li>
          </ul>
        </li>
        <li>
          제 14 조 (게시물 관리)
          <ul className='delth3'>
            <li>
              회사는 건전한 통신문화 정착과 효율적인 사이트 운영을 위하여 이용자가 게시하거나 제공하는 자료가 제12조에
              해당한다고 판단되는 경우에 임의로 삭제, 자료이동, 등록거부를 할 수 있다.
            </li>
          </ul>
        </li>
        <li>
          제 15 조 (서비스 이용 책임)
          <ul className='delth3'>
            <li>
              이용자는 회사에서 권한 있는 사원이 서명한 명시적인 서면에 구체적으로 허용한 경우를 제외하고는 서비스를
              이용하여 불법상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌기 광고, 음란 사이트를 통한 상업행위,
              상용 S/W 불법제공 등을 할 수 없다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등
              법적 조치 등에 관해서는 회사가 책임을 지지 않는다.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>제 5 장 (재화의 주문 및 결제 관련)</div>
      <ul className='delth2'>
        <li>
          제 16 조 (결제방법)
          <ul className='delth3'>
            <li>
              ‘회원’은 ‘회사’에서 판매하는 재화에 대하여 ‘선불카드, 직불카드, 신용카드 등의 각종 카드 결제 수단’을
              이용하여 결제할 수 있다. 이때 ‘회사’는 이용자의 지급방법에 대하여 재화외 어떠한 명목의 수수료를 추가
              징수하지 않는다.
            </li>
            <li>
              ① ‘회사’는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 한다. 주문확인에 대한 내용은
              해당게시판에서 확인 할 수 있다.
            </li>
            <li>
              ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청
              변경 및 취소를 요청할 수 있고 ‘회사’는 배송전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라
              처리한다. 다만 이미 대금을 지불한 경우에는 제18조의 ‘반품규정’을 따른다.
            </li>
          </ul>
        </li>
        <li>
          제 17 조 (배송정책)
          <ul className='delth3'>
            <li>
              ① ‘회사’는 이용자와 재화의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 결제를 실시한 날부터 7일
              이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취한다.
            </li>
            <li>
              ② ‘회사’는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 제품을
              구매하는 웹 페이지 하단에 명시한다. 만약 ‘회사’가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의
              손해를 배상한다. 하지만 ‘회사’의 고의과실이 없음을 입증한 경우에는 그러하지 아니한다.
            </li>
          </ul>
        </li>
        <li>
          18조 (취소 및 반품 환불 규정)
          <ul className='delth3'>
            <li>
              ‘회사’는 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그
              사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에
              환급하거나 환급에 필요한 조치를 한다.
            </li>
            <li>
              ① 재화가 발송 되기전 이용자가 결제를 취소할 경우 ‘회사’는 해당 주문건을 취소 처리하고 카드결제 승인을
              취소한다.
            </li>
            <li>
              ② 재화가 발송 된 이후 결제 취소는 불가하다. 단, ‘회사’의 부주의 ‘배송’상의 문제로 인한 재화의 파손, 변질의
              경우 ‘회사’는 이용자에게 구매 금액의 반품 및 환불 조치 및 교환 조치를 취한다.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>제 6 장 기타</div>
      <ul className='delth2'>
        <li>
          제 19 조 (면책 및 손해배상)
          <ul className='delth3'>
            <li>
              ① 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사의 서비스 제공 책임이
              면제된다.
            </li>
            <li>
              ② 회사는 이용자간 또는 이용자와 제3자간의 상호거래 관계에서 발생되는 결과에 대하여 어떠한 책임도 부담하지
              않는다.
            </li>
            <li>
              ③ 회사는 이용자가 게시판에 게재한 정보, 자료, 내용 등에 관하여 사실의 정확성, 신뢰도 등에 어떠한 책임도
              부담하지 않으며 이용자는 본인의 책임 아래 본 사이트를 이용해야 한다.
            </li>
            <li>
              ④ 이용자가 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 무료로 제공되는
              서비스 이용과 관련해 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 이용자에게 있다.
            </li>
            <li>
              ⑤ 아이디와 비밀번호의 관리 및 이용자의 부주의로 인하여 발생되는 손해 또는 제3자에 의한 부정사용 등에 대한
              책임은 이용자에게 있다.
            </li>
            <li>
              ⑥ 이용자가 본 약관의 규정을 위반함으로써 회사에 손해가 발생하는 경우 이 약관을 위반한 이용자는 회사에
              발생한 모든 손해를 배상해야 하며 동 손해로부터 회사를 면책시켜야 한다.
            </li>
          </ul>
        </li>
        <li>
          제 20 조 (개인신용정보 제공 및 활용에 대한 동의서)
          <ul className='delth3'>
            <li>
              회사가 회원 가입과 관련해 취득한 개인 신용 정보는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라
              타인에게 제공 및 활용 시 이용자의 동의를 얻어야 한다. 이용자의 동의는 회사가 회원으로 가입한 이용자의
              신용정보를 신용정보기관, 신용정보업자 및 기타 이용자 등에게 제공해 이용자의 신용을 판단하기 위한 자료로서
              활용하거나 공공기관에서 정책자료로 활용하는데 동의하는 것으로 간주한다.
            </li>
          </ul>
        </li>
        <li>
          제 21 조 (분쟁의 해결)
          <ul className='delth3'>
            <li>
              ① 회사와 이용자는 본 사이트 이용과 관련해 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 해야
              한다.
            </li>
            <li>
              ② 제1항의 규정에도 불구하고 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 회사의 본사 소재지를 관할하는
              법원의 관할로 본다.
            </li>
          </ul>
        </li>
      </ul>
      <ul className='delth2'>
        <li>
          부칙
          <ul className='delth3'>
            <li>본 약관은 2021년 12월 01일부터 적용한다.</li>
          </ul>
        </li>
        {/* <li>
                개인정보/청소년보호/위치정보 관리 책임자
                <ul className="delth3">
                  <li>
                    이름 : 한철승
                    <br />
                    연락처: 070-7932-2584
                    <br />
                    E-mail : kevin.han@globepoint.co.kr
                  </li>
                </ul>
              </li>
              <li>
                개인정보/청소년보호/위치정보 관리 담당자
                <ul className="delth3">
                  <li>
                    이름 : 한철승
                    <br />
                    연락처: 070-7932-2584
                    <br />
                    E-mail : kevin.han@globepoint.co.kr
                  </li>
                </ul>
              </li> */}
      </ul>
    </div>
  );
};

const AgreeOneUS = () => {
  return (
    <div className='agree-contents'>
      <div className='delth1'>Chapter 1, General Rules.</div>
      <ul className='delth2'>
        <li>
          Article 1 (Purpose)
          <ul className='delth3'>
            <li>
              The terms and conditions are operated by Globepoint with the website (https://lifesports.mrware.us). The
              purpose is to implement the terms and conditions of use of all services on mrware.us and all other
              necessary matters.
            </li>
          </ul>
        </li>
        <li>
          Article 2 (Definition of Terms)
          <ul className='delth3'>
            <li>The terms used in these terms and conditions are defined as follows:</li>
            <li>
              ① Member: An individual who entered basic member information and was granted an ID by signing a service
              use contract with the company.
            </li>
            <li>
              ② ID: A combination of letters and numbers selected by members and approved by the company for member
              identification and member use of services.
            </li>
            <li>
              ③ Password: A combination of letters and numbers selected by a member to protect his/her confidentiality
              in communication.
            </li>
            <li>④ Termination: Termination of the contract of use by the company or a member.</li>
          </ul>
        </li>
        <li>
          Article 3 (Disclosure of Terms and Conditions and Changes in Effect)
          <ul className='delth3'>
            <li>
              ① These terms and conditions are posted and disclosed on the membership screen, and the company may change
              the terms and conditions if there are important business reasons or changes, and the changed terms and
              conditions are disclosed through notice.
            </li>
            <li>
              ② These terms and conditions and the terms and conditions changed according to the company's circumstances
              in the future become effective by disclosing them to users.
            </li>
          </ul>
        </li>
        <li>
          Article 4 (Non-Terms and Conditions)
          <ul className='delth3'>
            <li>
              If some matters not specified in these terms and conditions are implemented in the Korean Law Information
              Center, the Telecommunications Business Act, The Information and Communication Promotion Act, The
              Regulation of Terms and Conditions, The Electronic Transactions Act, The Electronic Signature Act, the
              Consumer Protection Act, etc.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>Chapter 2, Contract for Use.</div>
      <ul className='delth2'>
        <li>
          Article 5 (Application for use)
          <ul className='delth3'>
            <li>
              ① Applicants can apply for use by agreeing to these terms and conditions and personal information
              protection policies in the membership registration guide and pressing the "Confirm" button through
              registration procedures (company's subscription application form).
            </li>
            <li>
              ② Applicants for use must use their real names and actual information, and may apply for one use for one
              date of birth.
            </li>
            <li>
              ③ Users who do not enter real names or actual information cannot receive legal protection and may be
              restricted from using the service.
            </li>
          </ul>
        </li>
        <li>
          Article 6 (approval of application for use)
          <ul className='delth3'>
            <li>
              ① The company approves the use of the service to the applicant for use under Article 5 except in the cases
              of paragraphs (2) and 3.
            </li>
            <li>
              ② The company may withhold consent until the reasons for the restriction are resolved in cases falling
              under the following.
              <br />
              A. If there is no room for service-related facilities,
              <br />
              B. In case of technical difficulties,
              <br />
              C. Where it is deemed necessary due to other company circumstances.
            </li>
            <li>
              ③ The company may refuse to accept if it falls under the following matters.
              <br />
              A. In the case of an application using the name of another person,
              <br />
              B. In a case where an application is made for the purpose of hindering the well-being and order or customs
              of society.
              <br />
              C. In the case where the requirements for application for use set by the company are insufficient,
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>Chapter 3 The obligations of the parties to the contract.</div>
      <ul className='delth2'>
        <li>
          Article 7 (Company's obligation)
          <ul className='delth3'>
            <li>① The company is obligated to operate the site stably and continuously.</li>
            <li>
              ② If the company deems that the opinions or complaints raised by the user are justifiable, it shall
              immediately deal with them. However, if it is difficult to process immediately, the reason and processing
              schedule shall be notified to the user through notice or e-mail.
            </li>
            <li>
              ③ In the case of paragraph (1), exceptions shall be made in cases where there is a request from the
              relevant agency and the Information and Communication Ethics Committee for investigative purposes, or a
              warrant is presented, or other relevant statutes.
            </li>
          </ul>
        </li>
        <li>
          Article 8 (User's Duty)
          <ul className='delth3'>
            <li>
              ① Users must be familiar with and comply with these terms and conditions, company announcements, and site
              usage information, and shall not engage in any other acts that interfere with the business of the company.
            </li>
            <li>
              ② Users are not allowed to engage in any for-profit activities using this site without prior approval from
              the company.
            </li>
            <li>
              ③ Users may not copy, duplicate, change, translate, publish, broadcast, or provide information obtained
              through this site to others without prior consent from the company.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>Chapter 4. Provision and use of services.</div>
      <ul className='delth2'>
        <li>
          Article 9 (Service)
          <ul className='delth3'>
            <li>① Users use the site in compliance with the provisions of these terms and conditions.</li>
            <li>
              ② Matters concerning the use of services not specified in these terms and conditions shall be determined
              by the company and posted on the "Notice" or notified separately.
            </li>
          </ul>
        </li>
        <li>
          Article 10 (Providing information)
          <ul className='delth3'>
            <li>
              The company may provide information to members through e-mail or letter mail on various information deemed
              necessary by members while using the service.
            </li>
          </ul>
        </li>
        <li>
          Article 11 (Commercial posting)
          <ul className='delth3'>
            <li>
              ① In relation to the operation of the service, the company may post advertisements on the service screen,
              homepage, e-mail, etc.
            </li>
            <li>
              ② The company is not responsible for any losses or damages incurred as a result of members participating
              in advertisers' promotional activities posted on the site or as a result of communication or transactions.
            </li>
          </ul>
        </li>
        <li>
          Article 12 (Restriction of Service Use)
          <ul className='delth3'>
            <li>
              If the use and act of this site falls under each of the following paragraphs, the company may restrict the
              use of the relevant user.
            </li>
            <li>① In the case of harming public order, customs, and other social order,</li>
            <li>
              ② Where it is objectively recognized that it is for the purpose of a criminal act or is related to other
              criminal acts.
            </li>
            <li>
              ③ In the case of damaging the reputation of others or significantly hindering the use of other people's
              services
            </li>
            <li>
              ④ In the case of continuously transmitting content or advertising information contrary to the will of
              another person, etc.
            </li>
            <li>
              ⑤ In a case where the sound operation of the service is hindered by hacking, distribution of computer
              viruses, etc.
            </li>
            <li>
              ⑥ In the case where it is determined that another user or a third party's intellectual property rights are
              violated, or that an intellectual property right holder may claim infringement of intellectual property
              rights.
            </li>
            <li>⑦ In the case of stealing another person's ID and password,</li>
            <li>
              ⑧ In the case of violation of other relevant laws and regulations, and in the case where the company
              determines that it is inappropriate as an employer,
            </li>
          </ul>
        </li>
        <li>
          Article 13 (Suspension of service provision)
          <ul className='delth3'>
            <li>The company may suspend the provision of all or part of the service in any of the following cases.</li>
            <li>
              ① In the case where a key telecommunication business operator or Internet network operator stipulated in
              the Telecommunications Business Act suspends the service,
            </li>
            <li>② If it's impossible to provide services due to a power outage,</li>
            <li>③ In the case where it is inevitable due to the relocation, repair, or construction of facilities,</li>
            <li>
              ④ In a case where it is difficult to provide normal services due to a failure of service facilities or a
              flood of service use, etc.
            </li>
            <li>
              ⑤ In the event of an exhibition, incident, natural disaster, or equivalent national emergency, or a
              possible occurrence,
            </li>
          </ul>
        </li>
        <li>
          Article 14 (Management of posts)
          <ul className='delth3'>
            <li>
              In order to establish a sound communication culture and efficient site operation, the company may
              arbitrarily delete, move, or refuse to register if it is determined that the data posted or provided by
              the user falls under Article 12.
            </li>
          </ul>
        </li>
        <li>
          Article 15 (Responsibility for Service Use)
          <ul className='delth3'>
            <li>
              Users cannot engage in sales activities to sell illegal products using services, especially hacking,
              money-making advertisements, commercial activities through pornographic sites, or illegal provision of
              commercial S/W, unless the company specifically allows them to sign explicit documents signed by
              authorized employees. The company is not responsible for legal measures such as consequences and losses of
              business activities that have occurred in violation of this, arrest by related agencies, etc.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>Chapter 5 (Regarding the order and payment of goods)</div>
      <ul className='delth2'>
        <li>
          Article 16 (Payment Method)
          <ul className='delth3'>
            <li>
              "Member" can pay for goods sold by "Company" using "various card payment methods such as prepaid cards,
              debit cards, credit cards, etc." At this time, the "company" does not collect additional fees in any name
              other than goods for the user's payment method.
            </li>
            <li>
              ① The "company" notifies the user of the receipt confirmation when there is a user's application for
              purchase. Information on order confirmation can be found on the bulletin board.
            </li>
            <li>
              ② If there is a disagreement in the expression of intention, the user who has received the notification
              may immediately request the change or cancellation of the purchase application, and the "company" shall
              handle the request without delay if the user requests it before delivery. However, if the payment has
              already been made, the "return regulation" of Article 18 shall be followed.
            </li>
          </ul>
        </li>
        <li>
          Article 17 (Delivery policy)
          <ul className='delth3'>
            <li>
              ① The "Company" shall take other necessary measures, such as custom production, packaging, etc., so that
              the user can deliver goods, etc., within seven days from the date of payment, unless there is a separate
              agreement with the user regarding the timing of supply of goods.
            </li>
            <li>
              ② "Company" specifies at the bottom of the web page where users purchase goods, such as delivery means,
              delivery cost burden by means, and delivery period by means. If the "company" exceeds the agreed delivery
              period, the user shall compensate for the damage caused by it. However, this is not the case if it proves
              that the "company" has no intentional negligence.
            </li>
          </ul>
        </li>
        <li>
          Article 18 (Cancellation and Return Refund Regulations)
          <ul className='delth3'>
            <li>
              When the "company" cannot deliver or provide the goods requested by the user due to sold out, etc., the
              "company" shall notify the user of the reason without delay and refund or take necessary measures within
              three business days from the date of receipt of the payment.
            </li>
            <li>
              ① If the user cancels the payment before the goods are sent, the "company" cancels the order and cancels
              the approval for card payment.
            </li>
            <li>
              ② Payment cancellation is not possible after the goods have been sent. However, in the case of damage or
              deterioration of goods due to the careless "delivery" problem of the "company", the "company" shall take
              measures to return, refund, and exchange the purchase amount to the user.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>Chapter 6. ETC.</div>
      <ul className='delth2'>
        <li>
          Article 19 (Exemption and Compensation for Damages)
          <ul className='delth3'>
            <li>
              ① If the service cannot be provided due to natural disasters or equivalent force majeure, the company's
              responsibility for providing services is exempted.
            </li>
            <li>
              ② The company shall not bear any responsibility for the consequences arising from the mutual transaction
              relationship between users or between users and third parties.
            </li>
            <li>
              ③ The company shall not bear any responsibility for the accuracy and reliability of facts regarding the
              information, data, and contents posted by the user on the bulletin board, and the user shall use this site
              under his/her responsibility.
            </li>
            <li>
              ④ The user is responsible for any disadvantages related to damage to data posted or transmitted by the
              user, selection of data, or other free use of services.
            </li>
            <li>
              ⑤ The user is responsible for the management of IDs and passwords and for damages caused by the
              carelessness of the user or fraudulent use by a third party.
            </li>
            <li>
              ⑥ If a user violates the provisions of these terms and conditions and causes damage to the company, the
              user who violates these terms and conditions shall compensate the company for all damages incurred and
              indemnify the company from such damages.
            </li>
          </ul>
        </li>
        <li>
          Article 20 (Consent to the Provision and Utilization of Personal Credit Information)
          <ul className='delth3'>
            <li>
              Personal credit information acquired by the company in connection with membership registration pursuant to
              Article 23 of the Credit Information Utilization and Protection Act. When providing and using it to
              others, the consent of the user must be obtained. The consent of the user is the consent of the user who
              joined the company as a member Credit information is provided to credit information agencies, credit
              information providers, and other users to determine the credit of users. It is considered to be used or
              agreed to be used as policy data by public institutions.
            </li>
          </ul>
        </li>
        <li>
          Article 21 (Resolution of Dispute)
          <ul className='delth3'>
            <li>
              ① The company and the user must make all necessary efforts to smoothly resolve disputes arising from the
              use of this site. do.
            </li>
            <li>
              ② Notwithstanding the provisions of paragraph (1), if a lawsuit is filed due to the dispute, the lawsuit
              has jurisdiction over the location of the company's headquarters. It shall be under the jurisdiction of
              the court.
            </li>
          </ul>
        </li>
      </ul>
      <ul className='delth2'>
        <li>
          Supplementary rule.
          <ul className='delth3'>
            <li>This Terms and Conditions shall apply from December 01st, 2021.</li>
          </ul>
        </li>
        {/* <li>
                개인정보/청소년보호/위치정보 관리 책임자
                <ul className="delth3">
                  <li>
                    이름 : 한철승
                    <br />
                    연락처: 070-7932-2584
                    <br />
                    E-mail : kevin.han@globepoint.co.kr
                  </li>
                </ul>
              </li>
              <li>
                개인정보/청소년보호/위치정보 관리 담당자
                <ul className="delth3">
                  <li>
                    이름 : 한철승
                    <br />
                    연락처: 070-7932-2584
                    <br />
                    E-mail : kevin.han@globepoint.co.kr
                  </li>
                </ul>
              </li> */}
      </ul>
    </div>
  );
};

const AgreeTwoKR = () => {
  return (
    <div className='agree-contents'>
      글로브포인트('https://lifesports.mrware.us/'이하 '생활체육')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및
      권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
      <br />
      <br />
      글로브포인트('생활체육') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여
      공지할 것입니다.
      <br />
      <br />○ 본 방침은부터 2021년 12월 1일부터 시행됩니다.
      <br />
      <br />
      <div className='delth1'>1. 개인정보의 처리 목적</div>
      <ul className='delth2'>
        <li>
          글로브포인트('https://lifesports.mrware.us/'이하 '생활체육')은(는) 개인정보를 다음의 목적을 위해 처리합니다.
          처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할
          예정입니다.
        </li>
        <li>
          가. 홈페이지 회원가입 및 관리
          <ul className='delth3'>
            <li>
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에
              따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종
              고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
            </li>
          </ul>
        </li>
        <li>
          나. 민원사무 처리
          <ul className='delth3'>
            <li>
              민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를
              처리합니다.
            </li>
          </ul>
        </li>
        <li>
          다. 재화 또는 서비스 제공
          <ul className='delth3'>
            <li>서비스 제공, 콘텐츠 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.</li>
          </ul>
        </li>
        <li>
          라. 마케팅 및 광고에의 활용
          <ul className='delth3'>
            <li>
              신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적
              특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한
              통계 등을 목적으로 개인정보를 처리합니다.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>2. 개인정보 파일 현황</div>
      <ul className='delth2'>
        <li>2-1. 개인정보 파일명 : 생활체육</li>
        <li>
          <ul className='delth3'>
            <li>
              - 개인정보 항목 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 이름, 직업, 소속, 활동분야, 신용카드정보,
              은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 법정대리인 이름, 법정대리인 자택
              전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호
            </li>
            <li>- 수집방법 : 홈페이지</li>
            <li>- 보유근거 : 홈페이지 회원정보 수집 등에 관한 기록</li>
            <li>- 보유기간 : 영구</li>
            <li>
              - 관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년, 소비자의 불만 또는 분쟁처리에 관한 기록 :
              3년, 대금결제 및 재화 등의 공급에 관한 기록 : 5년, 계약 또는 청약철회 등에 관한 기록 : 5년, 표시/광고에
              관한 기록 : 6개월
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>3. 개인정보의 처리 및 보유 기간</div>
      <ul className='delth2'>
        <li>
          ① 글로브포인트('생활체육')은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에
          동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
        </li>
        <li>
          ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
          <ul className='delth3'>
            <li>3-1. 홈페이지 회원가입 및 관리</li>
            <li>
              홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터 영구까지 위 이용목적을 위하여
              보유.이용됩니다.
            </li>
            <li>-보유근거 : 홈페이지 회원정보 수집 등에 관한 기록</li>
            <li>
              -관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
              <br />
              2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
              <br />
              3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
              <br />
              4) 계약 또는 청약철회 등에 관한 기록 : 5년
              <br />
              5) 표시/광고에 관한 기록 : 6개월
              <br />
              -예외사유 :
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>4. 개인정보의 제3자 제공에 관한 사항</div>
      <ul className='delth2'>
        <li>
          ① 글로브포인트('https://lifesports.mrware.us/'이하 '생활체육')은(는) 정보주체의 동의, 법률의 특별한 규정 등
          개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
        </li>
        <li>
          ② 글로브포인트('https://lifesports.mrware.us/')은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
        </li>
        <li>
          4-1. 글로브포인트
          <ul className='delth3'>
            <li>- 개인정보를 제공받는 자 : 글로브포인트</li>
            <li>
              - 제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 이름, 직업, 신용카드정보,
              은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 법정대리인 이름, 법정대리인 자택
              전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호
            </li>
            - 제공받는 자의 보유.이용기간: 영구
            <li />
          </ul>
        </li>
      </ul>
      <div className='delth1'>5. 개인정보처리 위탁</div>
      <ul className='delth2'>
        <li>
          ① 글로브포인트('생활체육')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고
          있습니다.
          <ul className='delth3'>
            <li>
              5-1. 글로브포인트
              <br />- 위탁받는 자 (수탁자) : 회원가입
              <br />- 위탁하는 업무의 내용 : 구매 및 요금 결제, 본인인증(금융거래, 금융서비스), 회원제 서비스 이용에
              따른 본인확인, 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공
              <br />- 위탁기간 : 영구
            </li>
          </ul>
        </li>
        <li>
          ② 글로브포인트('https://lifesports.mrware.us/'이하 '생활체육')은(는) 위탁계약 체결시 개인정보 보호법 제25조에
          따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독,
          손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
          있습니다.
        </li>
        <li>
          ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
        </li>
      </ul>
      <div className='delth1'>6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법</div>
      <ul className='delth2'>
        <li>이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</li>
        <ul className='delth3'>
          <li>
            ① 정보주체는 글로브포인트에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수
            있습니다.
          </li>
          <li>
            ② 제1항에 따른 권리 행사는글로브포인트에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편,
            모사전송(FAX) 등을 통하여 하실 수 있으며 글로브포인트은(는) 이에 대해 지체 없이 조치하겠습니다.
          </li>
          <li>
            ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이
            경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
          </li>
          <li>
            ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한
            될 수 있습니다.
          </li>
          <li>
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를
            요구할 수 없습니다.
          </li>
          <li>
            ⑥ 글로브포인트은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를
            한 자가 본인이거나 정당한 대리인인지를 확인합니다.
          </li>
        </ul>
      </ul>
      <div className='delth1'>7. 처리하는 개인정보의 항목 작성 </div>
      <ul className='delth2'>
        <li>
          ① 글로브포인트('https://lifesports.mrware.us/'이하 '생활체육')은(는) 다음의 개인정보 항목을 처리하고 있습니다.
          <ul className='delth3'>
            <li>
              7-1. 홈페이지 회원가입 및 관리
              <br />- 필수항목 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 이름, 직업, 소속, 활동분야, 신용카드정보,
              은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 법정대리인 이름, 법정대리인 자택
              전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호
              <br />- 선택항목 :
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>8. 개인정보의 파기</div>
      <ul className='delth2'>
        <li>
          글로브포인트('생활체육')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를
          파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
        </li>
        <li>
          -파기절차
          <br />
          이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에
          따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른
          목적으로 이용되지 않습니다.
        </li>
        <li>
          -파기기한
          <br />
          이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리
          목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가
          불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
        </li>
        <li>
          -파기방법
          <br />
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
          <br />
          종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        </li>
      </ul>
      <div className='delth1'>9. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</div>
      <ul className='delth2'>
        <li>
          ① 글로브포인트 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를
          사용합니다. ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의
          정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        </li>
        <li>
          가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
          여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
        </li>
        <li>
          나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구{'>'}인터넷 옵션{'>'}개인정보 메뉴의 옵션 설정을 통해
          쿠키 저장을 거부 할 수 있습니다.
        </li>
        <li>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
      </ul>
      <div className='delth1'>10. 개인정보 보호책임자 작성 </div>
      <ul className='delth2'>
        <li>
          ① 글로브포인트(‘https://lifesports.mrware.us/’이하 ‘생활체육) 은(는) 개인정보 처리에 관한 업무를 총괄해서
          책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
          지정하고 있습니다.
          <ul className='delth3'>
            <li>
              ▶ 개인정보 보호책임자 <br />
              성명 :허정민
              <br />
              직급 :과장
              <br />
              연락처 :031-911-0609, gpsales@globepoint.co.kr, 031-911-0602
              <br />※ 개인정보 보호 담당부서로 연결됩니다.
              <br />
            </li>
            <li>
              ▶ 개인정보 보호 담당부서 <br />
              부서명 : 서비스 사업부
              <br />
              담당자 : 허정민
              <br />
              연락처 :031-911-0609 (직통) , gpsales@globepoint.co.kr, 031-911-0602
            </li>
          </ul>
        </li>
        <li>
          ② 정보주체께서는 글로브포인트(‘https://lifesports.mrware.us/’이하 ‘생활체육) 의 서비스(또는 사업)을
          이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및
          담당부서로 문의하실 수 있습니다. 글로브포인트(‘https://lifesports.mrware.us/’이하 ‘생활체육) 은(는) 정보주체의
          문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
        </li>
      </ul>
      <div className='delth1'>11. 개인정보 처리방침 변경 </div>
      <ul className='delth2'>
        <li>
          ① 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는
          경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
        </li>
      </ul>
      <div className='delth1'>12. 개인정보의 안전성 확보 조치</div>
      <ul className='delth2'>
        <li>
          글로브포인트('생활체육')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및
          물리적 조치를 하고 있습니다.
        </li>
        <li>
          1. 개인정보 취급 직원의 최소화 및 교육
          <br />
          개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고
          있습니다.
        </li>
        <li>
          2. 내부관리계획의 수립 및 시행
          <br />
          개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
        </li>
        <li>
          3. 개인정보의 암호화
          <br />
          이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일
          및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
        </li>
        <li>
          4. 접속기록의 보관 및 위변조 방지
          <br />
          개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지
          않도록 보안기능 사용하고 있습니다.
        </li>
        <li>
          5. 개인정보에 대한 접근 제한
          <br />
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를
          위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
        </li>
      </ul>
    </div>
  );
};

const AgreeTwoUS = () => {
  return (
    <div className='agree-contents'>
      According to the Personal Information Protection Act, Globepoint Inc. with the website
      (https://lifesports.mrware.us/') has the following treatment policies to protect users' personal information,
      protect their rights and interests, and handle user grievances related to personal information smoothly.
      <br />
      <br />
      Globepoint Inc. ('Life Sports') will announce through the website notice (or individual notice) if the company
      revises its personal information processing policy.
      <br />
      <br />○ This policy will take effect on December 1, 2021.
      <br />
      <br />
      <div className='delth1'>1. Purpose of processing personal information.</div>
      <ul className='delth2'>
        <li>
          Globepoint ("https://lifesports").mrware.us/' (hereinafter referred to as 'sports for all') processes personal
          information for the following purposes. The processed personal information is not used for purposes other than
          the following purposes, and if the purpose of use changes, prior consent will be sought.
        </li>
        <li>
          A. Membership registration and management on the website.
          <ul className='delth3'>
            <li>
              Personal information is processed for the purpose of verifying membership intention, identifying and
              authenticating membership services, maintaining and managing membership qualifications, preventing illegal
              use of services, confirming legal representatives' consent when collecting personal information for
              children under the age of 14,
            </li>
          </ul>
        </li>
        <li>
          B. Handling civil complaints.
          <ul className='delth3'>
            <li>
              Personal information is processed for the purpose of identifying civil petitioners, checking civil
              complaints, contacting and notifying them for fact-finding, and notifying them of the results of
              processing.
            </li>
          </ul>
        </li>
        <li>
          C. Providing goods or services.
          <ul className='delth3'>
            <li>
              Personal information is processed for the purpose of service provision, content provision, identity
              authentication, age authentication, fee payment and settlement.
            </li>
          </ul>
        </li>
        <li>
          D. Use for marketing and advertising.
          <ul className='delth3'>
            <li>
              Personal information is processed for the purpose of developing new services (products) and providing
              customized services, providing events and advertising information and opportunities to participate,
              providing services based on demographic characteristics, validating services, identifying access
              frequency, or statistics on members' use of services.
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>2. Status of personal information files.</div>
      <ul className='delth2'>
        <li>Personal information file name: Sports for All</li>
        <li>
          <ul className='delth3'>
            <li>
              - Personal information items: e-mail, mobile phone number, password, date of birth, name, occupation,
              affiliation, activity, credit card information, bank account information, service usage record, access
              log, cookie, access IP information, payment record, legal representative home phone number, legal
              representative home address, legal representative mobile phone number
            </li>
            <li>- How to collect: Homepage</li>
            <li>- Grounds for retention: Records of collection of membership information on the website, etc.</li>
            <li>- Retention period: Permanent</li>
            <li>
              - Related Acts and subordinate statutes: records on the collection/processing, etc. of credit information:
              records on consumer complaints or dispute settlement: records on the supply of goods, etc.: 5 years,
              records on contract or withdrawal of subscription: 6 months
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>3. Processing and retention period of personal information.</div>
      <ul className='delth2'>
        <li>
          ① Globepoint ('Living Sports') processes and holds personal information within the period of possession and
          use of personal information or personal information that has been agreed to collect personal information from
          the data subject under the law.
        </li>
        <li>
          ② Each personal information processing and retention period is as follows.
          <ul className='delth3'>
            <li>3-1. Membership registration and management on the website.</li>
            <li>
              Collection of personal information related to membership registration and management on the website.
              Retention for the above purpose of use from the date of consent to the use to permanent. It's used.
            </li>
            <li>-Grounds for possession: Records of collection of membership information on the website, etc.</li>
            <li>
              -Related statutes: 1) Records on the collection/processing, use, etc. of credit information: 3 years
              <br />
              2) Records of consumer complaints or disputes: 3 years
              <br />
              3) Records of payment and supply of goods, etc.: 5 years.
              <br />
              4) Records of contract or withdrawal of subscription, etc.:
              <br />
              5) Records of Indication/Advertisement: 6 months
              <br />
              -EXCEPTION:
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>4. Matters concerning the provision of personal information to a third party.</div>
      <ul className='delth2'>
        <li>
          ① Globepoint ("https://lifesports").mrware.us/' (hereinafter referred to as "sports for all") provides
          personal information to third parties only if it falls under Articles 17 and 18 of the Personal Information
          Protection Act, such as the consent of the data subject and special provisions of the law.
        </li>
        <li>
          ② Globepoint ("https://lifesports").mrware.us/') provides personal information to third parties as follows.
        </li>
        <li>
          4-1. GlobePoint.
          <ul className='delth3'>
            <li>- Person who receives personal information: Globe Point</li>
            <li>
              - Purpose of using the recipient's personal information: e-mail, mobile phone number, password, date of
              birth, name, job, credit card information, bank account information, service usage record, access log,
              cookie, access IP information, payment record, legal representative home phone number, legal
              representative home address, legal representative mobile phone number.
            </li>
            - The possession of the recipient. Period of use: Permanently
            <li />
          </ul>
        </li>
      </ul>
      <div className='delth1'>5. Consignment of personal information processing.</div>
      <ul className='delth2'>
        <li>
          ① Globepoint ('Living Sports') entrusts personal information processing as follows for smooth personal
          information processing.
          <ul className='delth3'>
            <li>
              5-1. Globepoint.
              <br />- Consigned person (consignee): Membership registration
              <br />- Contents of the entrusted work: purchase and payment of fees, identity verification (financial
              transactions, financial services), use of membership services, development of new services (products) and
              customized services, provision of event and advertising information and opportunities to participate.
              <br />- Consignment period: Permanent.
            </li>
          </ul>
        </li>
        <li>
          ② Globepoint ("https://lifesports").mrware.us/' (hereinafter referred to as "living sports") stipulates
          documents on responsibilities such as prohibition of personal information processing, technical and
          administrative protection measures, restrictions on re-consignment, management and supervision of trustees,
          and supervises whether the trustee handles personal information safely.
        </li>
        <li>
          ③ If the contents of the consignment work or the trustee change, we will disclose it through this personal
          information processing policy without delay.
        </li>
      </ul>
      <div className='delth1'>
        6. Rights and obligations of the data subject and legal representative, and the method of exercising them.
      </div>
      <ul className='delth2'>
        <li>Users can exercise the following rights as personal information subjects.</li>
        <ul className='delth3'>
          <li>
            ① The data subject can exercise the right to view, correct, delete, and suspend personal information at any
            time against Globe Point.
          </li>
          <li>
            ② The exercise of rights under paragraph (1) can be made through written, e-mail, or mock-up (FAX) in
            accordance with Article 41 (1) of the Enforcement Decree of the Personal Information Protection Act, and
            Globepoint will take action without delay.
          </li>
          <li>
            ③ The exercise of rights under paragraph (1) may be made through an agent such as a legal representative of
            the data subject or a person who has been delegated. In this case, you must submit a power of attorney in
            accordance with attached Form 11 of the Enforcement Rule of the Personal Information Protection Act.
          </li>
          <li>
            ④ The rights of the data subject may be restricted under Articles 35 (5) and 37 (2) of the Personal
            Information Protection Act.
          </li>
          <li>
            ⑤ Requests for correction and deletion of personal information cannot be requested if other laws and
            regulations stipulate that personal information is subject to collection.
          </li>
          <li>
            ⑥ Globepoint checks whether the person who made the request is himself or a legitimate agent, such as
            reading the request for access, correction or deletion, or suspension of processing according to the right
            of the data subject.
          </li>
        </ul>
      </ul>
      <div className='delth1'>7. Create items for personal information to be processed.</div>
      <ul className='delth2'>
        <li>
          ① Globepoint ("https://lifesports").mrware.us/' (hereinafter referred to as 'sports for all') handles the
          following personal information items.
          <ul className='delth3'>
            <li>
              7-1. Membership registration and management on the website.
              <br />- Required items: e-mail, mobile phone number, password, date of birth, name, occupation,
              affiliation, activity, credit card information, bank account information, service usage record, access
              log, cookie, access IP information, payment record, legal representative home phone number, legal
              representative home address, legal representative mobile phone number
              <br />- Choice :
            </li>
          </ul>
        </li>
      </ul>
      <div className='delth1'>8. Destruction of personal information.</div>
      <ul className='delth2'>
        <li>
          Globepoint ('Living Sports') will destroy the personal information without delay if the purpose of processing
          personal information is achieved in principle. The procedure, deadline, and method of destruction are as
          follows.
        </li>
        <li>
          -The process of destroying it.
          <br />
          After achieving the purpose, the information entered by the user will be transferred to a separate DB
          (separate documents in the case of paper) and stored for a certain period in accordance with internal policies
          and other related laws and regulations. At this time, the personal information transferred to the DB is not
          used for any other purpose
        </li>
        <li>
          -except by law.
          <br />
          If the user's personal information retention period has elapsed, the personal information will be destroyed
          within five days of the end of the retention period, and if personal information becomes unnecessary, such as
          achieving the purpose of processing personal information, abolishing the service, or termination of the
          business.
        </li>
        <li>
          -How to break it down.
          <br />
          Electronic file-type information uses a technical method that cannot play records.
          <br />
          Personal information printed on paper is crushed with a grinder or destroyed through incineration.
        </li>
      </ul>
      <div className='delth1'>
        9. Matters concerning the installation, operation, and refusal of automatic personal information collection
        devices.
      </div>
      <ul className='delth2'>
        <li>
          ① Globepoint uses "cookie" that stores usage information and calls frequently to provide individual customized
          services. ② Cookies are a small amount of information sent by the server (http) used to run the website to the
          user's computer browser and are also stored on the user's PC computer's hard disk.
        </li>
        <li>
          A. The purpose of using cookies is to provide optimized information to users by identifying the types of
          visits and uses of each service and website visited by users, popular search terms, security access status,
          etc.
        </li>
        <li>
          B. Installation, operation, and rejection of cookies: Tools at the top of the web
          browser)Internet)Options)Setting options in the Personal Information menu allows you to refuse to save
          cookies.
        </li>
        <li>C. Refusing to save cookies may cause difficulties in using customized services.</li>
      </ul>
      <div className='delth1'>10. Person in charge of personal information protection </div>
      <ul className='delth2'>
        <li>
          ① Globepoint ("https://lifesports.mrware.us/) is in charge of handling personal information and designates a
          person in charge of personal information protection as follows for handling complaints and relief of damage by
          information subjects related to personal information processing.
          <ul className='delth3'>
            <li>
              ▶ PIC of personal information protection. <br />
              Name: Heo Jeong Min
              <br />
              Position: Manager
              <br />
              Contact Us : 031-911-0609, gpsales@globepoint.co.kr, 031-911-0602 ※ You will be connected to the PIC once
              you contact us.
              <br />
            </li>
            <li>
              ▶ Department of Personal Information Protection <br />
              Name of the department: Service division
              <br />
              Staff: Heo Jeongmin.
              <br />
              Contact : 031-911-0609 (directly connected), gpsales@globepoint.co.kr, 031-911-0602
            </li>
          </ul>
        </li>
        <li>
          ② The information subject is a glove point 'https://lifesports'.The service (or business) of "Sports for All"
          hereinafter referred to as mrware.us/' The person in charge of personal information protection for all
          inquiries, complaints, damage relief, etc. arising from the use, and You can contact the department in charge.
          Glovepoints ("https://lifesports").mrware.us/' hereinafter referred to as "Sports for All" is the subject of
          information. We will answer and process your inquiry without delay.
        </li>
      </ul>
      <div className='delth1'>11. Change the privacy policy </div>
      <ul className='delth2'>
        <li>
          ① This personal information processing policy applies from the date of enforcement, and has addition,
          deletion, and correction of changes in accordance with laws and policies. In this case, we will notify you 7
          days before the change is implemented.
        </li>
      </ul>
      <div className='delth1'>12. Measures to secure safety of personal information</div>
      <ul className='delth2'>
        <li>
          According to Article 29 of the Personal Information Protection Act, Globe Point ('Living Sports') is a
          technical/management necessary for securing safety as follows and We are taking physical measures.
        </li>
        <li>
          1. Minimization and training of employees handling personal information.
          <br />
          Measures to manage personal information by designating employees who handle personal information and
          minimizing it to the person in charge are implemented. I have it.
        </li>
        <li>
          2. Establishment and implementation of an internal management plan.
          <br />
          For the safe processing of personal information, we have established and implemented an internal management
          plan.
        </li>
        <li>
          3. Encryption of personal information.
          <br />
          The user's personal information is encrypted, stored, and managed, so only the user can know, and important
          data is a file. And we use separate security functions such as encrypting transmitted data or using file lock
          function.
        </li>
        <li>
          4. Storage of access records and prevention of forgery and alteration.
          <br />
          Records of access to the personal information processing system are stored and managed for at least 6 months,
          and access records are forged, stolen, or lost. We are using security functions to prevent it.
        </li>
        <li>
          5. Restrictions on access to personal information.
          <br />
          Access control to personal information through granting, changing, or canceling access to the database system
          that processes personal information. We are taking necessary measures for this, and we control unauthorized
          access from the outside using an intrusion blocking system.
        </li>
      </ul>
    </div>
  );
};

const AgreeThreeKR = () => {
  return (
    <div className='agree-contents'>
      <div className='delth1'>1. 개인정보 제3자 제공 동의</div>
      <ul className='delth2'>
        <li>
          1. 제공받는 자
          <ul className='delth3'>
            <li>- (주)글로브포인트</li>
          </ul>
        </li>
        <li>
          3. 제공받는 자의 이용 목적
          <ul className='delth3'>
            <li>- 개인 식별 및 서비스 이력 관리</li>
          </ul>
        </li>
        <li>
          3. 제공 항목
          <ul className='delth3'>
            <li>- 이름, 생년월일, 주소, 이메일, 전화번호, 직업</li>
          </ul>
        </li>
        <li>
          4. 제공받는 자의 보유 및 이용기간
          <ul className='delth3'>
            <li>이용목적의 달성 후 지체없이 파기</li>
          </ul>
        </li>
        <li>
          4. 동의 거부권리
          <ul className='delth3'>
            <li>
              위 개인정보 제공 동의를 거부하시더라도 회원 가입 시 제한은 없습니다. 다만, 마케팅 활용 서비스 안내 및
              참여에 제한이 있을 수 있습니다.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
const AgreeThreeUS = () => {
  return (
    <div className='agree-contents'>
      <div className='delth1'>1. Consent to provide personal information to a third party</div>
      <ul className='delth2'>
        <li>
          1. Person who is provided.
          <ul className='delth3'>
            <li>- Glovepoint Co., Ltd.</li>
          </ul>
        </li>
        <li>
          3. Purpose of use of the recipient.
          <ul className='delth3'>
            <li>- Personal identification and service history management</li>
          </ul>
        </li>
        <li>
          3. Items to be provided.
          <ul className='delth3'>
            <li>- Name, date of birth, address, email, phone number, occupation</li>
          </ul>
        </li>
        <li>
          4. The retention and period of use of the recipient.
          <ul className='delth3'>
            <li> Destroy without delay after achieving the purpose of use</li>
          </ul>
        </li>
        <li>
          4. Right to refuse consent.
          <ul className='delth3'>
            <li>
              Even if you refuse to agree to provide the above personal information, there is no restriction when
              signing up as a member. However, the guidance on marketing utilization services and There may be
              restrictions on participation.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
