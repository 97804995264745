import { useTranslation } from 'react-i18next';
import { login, logout } from 'hooks/useSession';
import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import useAuthRequest from 'libs/api/useAuthRequest';
import { getCookie, setCookie } from 'libs/cookie';
import { NotFoundPage } from 'pages/error/NotFoundPage';
import { JoinPage } from 'pages/JoinPage';
import { LoginPage } from 'pages/LoginPage';
import { MainPage } from 'pages/MainPage';
import { useCallback, useEffect } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { CmsPage } from 'pages/CmsPage';
import { MyInfoPage } from 'pages/MyInfoPage';
import { NoticePage } from 'pages/NoticePage';
import { BoardPage } from 'pages/BoardPage';
import useLocationQuery from 'hooks/useLocationQuery';

const App = () => {
  const { data, error } = useAuthRequest(`/v1.0/auths`);
  const localSotrageUser = localStorage.getItem('member_info');

  const loadMemberFormServer = useCallback(
    async (email) => {
      try {
        const memberResp = await LifeSportsAPI.get('/v1.0/members?email=' + email);

        if (memberResp.data.status) {
          const memberInfo = memberResp.data.data.memberInfo[0];

          //localstorage에 유저 정보가 있는데 로그인한 정보와 이메일이 다르다면 로그아웃
          if (localSotrageUser && JSON.parse(localSotrageUser).email !== memberInfo.email) {
            return logout();
          }

          return login(memberInfo);
        }
      } catch (error: any) {
        return logout();
        // if (!error.response.data.status) {
        //   return logout();
        // }
      }
    },
    [localSotrageUser],
  );

  useEffect(() => {
    if (error) {
      return logout();
    }
    if (!data) return;

    const checkRefreshToken = async () => {
      // 만료시간이 5분 남았다면 액세스 토큰을 재발급 해준다.
      if (data.data.exp - Date.now() / 1000 <= 290 && getCookie('isSaveSession') === 'true') {
        try {
          const res_refresh = await LifeSportsAPI.get(
            `/v1.0/auths/refresh?isSaveSession=${getCookie('isSaveSession')}`,
            {
              withCredentials: true,
              headers: { authorization: 'Bearer ' + getCookie('accessToken'), refresh: getCookie('refreshToken') },
            },
          );
          if (res_refresh.data.status) {
            setCookie(
              'accessToken',
              res_refresh.data.data.accessToken,
              res_refresh.data.data.accessOpions.expires,
              '/',
            );
            setCookie(
              'refreshToken',
              res_refresh.data.data.refreshToken,
              res_refresh.data.data.refreshOpions.expires,
              '/',
            );
          }
        } catch (err) {
          // console.log(err);
        }
      }
    };

    checkRefreshToken();

    const email = data.data.memberInfo.email;
    if (!email) return;

    loadMemberFormServer(email);
  }, [data, error, loadMemberFormServer]);

  const { i18n } = useTranslation();
  const { lang } = useLocationQuery();
  /* lang 값 변경될때 마다 해당 lang 변환 */
  useEffect(() => {
    if (lang === 'ko-KR' || localStorage.getItem('lang') === 'ko-KR') {
      i18n.changeLanguage('ko-KR');
      localStorage.setItem('lang', 'ko-KR');
    }
    if (lang === 'en-US' || localStorage.getItem('lang') === 'en-US') {
      i18n.changeLanguage('en-US');
      localStorage.setItem('lang', 'en-US');
    }

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en-US');
    }
  }, [i18n, lang]);

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route path='/sign-in' component={LoginPage} />
        <Route path='/sign-up' component={JoinPage} />
        <Route path='/myinfo' component={MyInfoPage} />
        <Route path='/cms' component={CmsPage} />
        <Route path='/notice' component={NoticePage} />
        <Route path='/board' component={BoardPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default App;
