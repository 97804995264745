import { deleteCookie } from 'libs/cookie';
import { MemberInfo } from 'libs/types';
import useSWR, { mutate } from 'swr';
import { setMemberInfo } from './useMember';

const KEY = 'sessions';

export default function useSession() {
  const { data: isSession = localStorage.getItem('member_info') ? true : false, mutate } = useSWR<boolean>(KEY);

  const login = (memberInfo: MemberInfo) => {
    mutate(true);
    if (!localStorage.getItem('member_info')) {
      localStorage.setItem(
        'member_info',

        JSON.stringify({
          email: memberInfo.email,
          joinDate: memberInfo.joinDate,
          lastLoginDate: memberInfo.lastLoginDate,
          phoneNumber: memberInfo.phoneNumber,
        }),
      );
    }
    setMemberInfo(memberInfo);
  };

  const logout = () => {
    mutate(false);
    if (localStorage.getItem('member_info')) {
      localStorage.removeItem('member_info');
    }
    deleteCookie('accessToken');
    deleteCookie('refreshToken');
    setMemberInfo(undefined);
  };

  return {
    isSession,
    login,
    logout,
  };
}

export const login = (memberInfo: MemberInfo) => {
  mutate(KEY, true);
  if (!localStorage.getItem('member_info')) {
    localStorage.setItem(
      'member_info',

      JSON.stringify({
        email: memberInfo.email,
        joinDate: memberInfo.joinDate,
        lastLoginDate: memberInfo.lastLoginDate,
        phoneNumber: memberInfo.phoneNumber,
      }),
    );
  }
  setMemberInfo(memberInfo);
};

export const logout = () => {
  mutate(KEY, false);
  if (localStorage.getItem('member_info')) {
    localStorage.removeItem('member_info');
  }
  deleteCookie('accessToken');
  deleteCookie('refreshToken');
  setMemberInfo(undefined);
};
