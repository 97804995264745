import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LoginBox.scss';
import isEmail from 'validator/es/lib/isEmail';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { login } from 'hooks/useSession';
import { getCookie } from 'libs/cookie';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Language } from 'libs/types';

export const LoginBox = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSaveSession, setIsSaveSession] = useState(getCookie('isSaveSession') === 'true' ? true : false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const lang = localStorage.getItem('lang') as Language;

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onChangePwd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const onChangeSaveSession = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsSaveSession(true);
    } else {
      setIsSaveSession(false);
    }
  };
  const onClickLogin = async () => {
    if (!email) {
      setErrorMessage(t('enterEmail'));
      return;
    }
    if (!isEmail(email)) {
      setErrorMessage(t('validEmail'));
      return;
    }
    if (!password) {
      setErrorMessage(t('enterPwd'));
      return;
    }
    if (!isStrongPassword(password, { minUppercase: 0, minSymbols: 0 })) {
      setErrorMessage(t('validPwd'));
      return;
    }

    try {
      const res = await LifeSportsAPI.post(
        '/v1.0/auths',
        { email, password, isSaveSession },
        { withCredentials: true },
      );
      if (res.data.status) {
        setErrorMessage('');
        const { accessToken } = res.data.data;

        const auth_res = await LifeSportsAPI.get('/v1.0/auths', {
          headers: { Authorization: 'Bearer ' + accessToken },
        });

        if (auth_res.data.status) {
          login(auth_res.data.data.memberInfo);
          history.push('/');
        }
      }
    } catch (err: any) {
      if (lang !== 'ko-KR') {
        return alert(err.response.data.message);
      } else {
        return alert(err.response.data.alert_message);
      }
    }
  };

  return (
    <div className='LoginBox'>
      <h2 className='login-title'>Log In</h2>
      <div className='login-inputs'>
        <div className='login-input'>
          <input type='text' placeholder={'ID (e-mail)'} onChange={(e) => onChangeEmail(e)} />
        </div>
        <div className='login-input'>
          <input type='password' maxLength={16} placeholder={'Password'} onChange={(e) => onChangePwd(e)} />
        </div>
      </div>
      <div className='keep-sign-in'>
        <label className='container'>
          {t('login_keepLoggedIn')}
          <input
            type='checkbox'
            onChange={(e) => onChangeSaveSession(e)}
            defaultChecked={getCookie('isSaveSession') === 'true' ? true : false}
          />
          <span className='checkmark'></span>
        </label>
      </div>
      <div className='error-msg'>{errorMessage && <label>*{errorMessage}</label>}</div>
      <div className='login-btn-box'>
        <div className='login-btn' onClick={onClickLogin}>
          {t('login_logIn')}
        </div>
      </div>
      <Link to={'/sign-up'}>
        <div className='sign-up-link'>{t('login_signUp')}</div>
      </Link>
    </div>
  );
};
