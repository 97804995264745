import { Template } from 'components/base/Template/Template';
import { LoginFlexBox } from 'components/login/LoginFlexBox/LoginFlexBox';
import useSession from 'hooks/useSession';
import { getCookie } from 'libs/cookie';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const LoginPage = () => {
  const { isSession } = useSession();
  const history = useHistory();

  useEffect(() => {
    const accessToken = getCookie('accessToken');

    if (accessToken && isSession) {
      history.push('/');
    }
  }, [isSession, history]);

  return (
    <Template>
      <LoginFlexBox />
    </Template>
  );
};
