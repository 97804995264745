import { DropDown, DropDownOption } from 'components/common/DropDown/DropDown';
import './JoinBox.scss';
import codes from 'country-calling-code';
import {
  AsYouType,
  CountryCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import React, { useState } from 'react';
import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import isEmail from 'validator/es/lib/isEmail';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Language } from 'libs/types';

type JoinBoxProps = {
  stepLevel: number;
  onChangeStepLevel(level: number): void;
};

export const JoinBox = ({ stepLevel, onChangeStepLevel }: JoinBoxProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const [pwdCheck, setPwdCheck] = useState<string>('');
  const [isUseableEmail, setIsUseableEmail] = useState<boolean>(false);
  const [isJoinPopup, setIsJoinPopup] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [memberType, setMemberType] = useState<string>('1');
  const [country, setCountry] = useState<CountryCode>('KR');
  const [notify, setNotify] = useState({
    email: '',
    password: '',
    passwordCheck: '',
    phoneNumber: '',
  });
  const history = useHistory();
  const lang = localStorage.getItem('lang') as Language;

  window.scrollTo(0, 0);

  const memberTypeOptions: DropDownOption[] = [
    { value: '1', label: t('Learner') },
    { value: '2', label: t('Instructor') },
  ];

  if (stepLevel !== 2) return null;

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsUseableEmail(false);

    if (!isEmail(e.target.value)) {
      setNotify({ ...notify, email: t('validEmail') });
    } else {
      setNotify({ ...notify, email: '' });
    }
  };

  const onChangePwd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPwd(e.target.value);

    if (!isStrongPassword(e.target.value, { minUppercase: 0, minSymbols: 0 })) {
      setNotify({ ...notify, password: t('validPwd') });
    } else {
      setNotify({ ...notify, password: '' });
    }
  };
  const onChangePwdCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPwdCheck(e.target.value);

    if (!isStrongPassword(e.target.value, { minUppercase: 0, minSymbols: 0 })) {
      setNotify({ ...notify, passwordCheck: t('validPwd') });
    } else {
      setNotify({ ...notify, passwordCheck: '' });
    }
  };

  const onChangeCountry = (option: DropDownOption) => {
    const codeInfo = codes.filter((code) => `${code.countryCodes[0].toString()} (${code.isoCode2})` === option.value);
    setCountry(codeInfo[0].isoCode2 as CountryCode);
  };

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _phoneNumber = new AsYouType(country).input(e.target.value);
    setPhoneNumber(_phoneNumber);

    if (
      isPossiblePhoneNumber(phoneNumber, country) === true &&
      isValidPhoneNumber(phoneNumber, country) === true &&
      validatePhoneNumberLength(phoneNumber, country) === undefined
    ) {
      setNotify({ ...notify, phoneNumber: '' });
    }
  };

  const onChangeMemberType = (option: DropDownOption) => {
    setMemberType(option.value);
  };

  const onClickMemberInfo = async () => {
    if (!email) {
      setNotify({ ...notify, email: t('enterEmail') });
      return;
    }
    if (!isEmail(email)) {
      setNotify({ ...notify, email: t('validEmail') });
      return;
    }

    try {
      const res_member = await LifeSportsAPI.get(`/v1.0/members?email=${email}&limit=1`);

      if (res_member.data.data.memberInfo.length > 0) {
        setNotify({ ...notify, email: t('alreadyUseEmail') });
        setIsUseableEmail(false);
      } else {
        setNotify({ ...notify, email: '' });
        setIsUseableEmail(true);
        return alert(t('join_validEmail'));
      }
    } catch (error: any) {
      if (lang !== 'ko-KR') {
        setNotify({ ...notify, email: error.response.data.message });
      } else {
        setNotify({ ...notify, email: error.response.data.alert_message });
      }
      setIsUseableEmail(false);
    }
  };

  const onClickJoinCheck = () => {
    if (!email) {
      setNotify({ ...notify, email: t('enterEmail') });
      return;
    }
    if (!isEmail(email)) {
      setNotify({ ...notify, email: t('validEmail') });
      return;
    }
    if (!isUseableEmail) {
      setNotify({ ...notify, email: t('confirmEmail') });
      return;
    }
    if (!pwd) {
      setNotify({ ...notify, password: t('enterPwd') });
      return;
    }
    if (!pwdCheck) {
      setNotify({ ...notify, passwordCheck: t('reEnterPwd') });
      return;
    }
    if (!isStrongPassword(pwd, { minUppercase: 0, minSymbols: 0 })) {
      setNotify({ ...notify, password: t('validPwd') });
      return;
    }
    if (!isStrongPassword(pwdCheck, { minUppercase: 0, minSymbols: 0 })) {
      setNotify({ ...notify, passwordCheck: t('validPwd') });
      return;
    }
    if (pwd !== pwdCheck) {
      setNotify({ ...notify, passwordCheck: t('invalidEmailPwd') });
      return;
    }
    if (
      isPossiblePhoneNumber(phoneNumber, country) === false ||
      isValidPhoneNumber(phoneNumber, country) === false ||
      validatePhoneNumberLength(phoneNumber, country) !== undefined
    ) {
      setNotify({ ...notify, phoneNumber: t('validPhone') });
      return;
    }

    setIsJoinPopup(true);
  };

  const onClickJoin = async () => {
    try {
      const res_member = await LifeSportsAPI.post('/v1.0/members', {
        email,
        pwd,
        pwdCheck,
        phoneNumber,
        receiveMarketing: false,
        memberType,
      });
      if (res_member.data.status) {
        setIsJoinPopup(false);
        alert(t('join_completed'));
        history.push('/sign-in');
      }
    } catch (error: any) {
      if (lang !== 'ko-KR') {
        return alert(error.response.data.message);
      } else {
        return alert(error.response.data.alert_message);
      }
    }
  };

  return (
    <div className='JoinBox'>
      <h2 className='join-title'>{t('join_header')}</h2>
      <div className='join-inputs'>
        <div className='join-input-box'>
          <div className='join-input'>
            <input type='text' placeholder={t('enterId')} onChange={(e) => onChangeEmail(e)} defaultValue={email} />
            <div className='duplicate-check' onClick={onClickMemberInfo}>
              {t('join_confirmEmail')}
            </div>
          </div>
          {notify.email && <p className='join-notify'>*{notify.email}</p>}
        </div>
        <div className='join-input-box'>
          <div className='join-input'>
            <input
              type='password'
              maxLength={16}
              placeholder={t('enterPwd')}
              onChange={(e) => onChangePwd(e)}
              defaultValue={pwd}
            />
          </div>
          <p className='join-notify'>*{t('pwdRule')}</p>
          {notify.password && <p className='join-notify'>*{notify.password}</p>}
        </div>
        <div className='join-input-box'>
          <div className='join-input'>
            <input
              type='password'
              placeholder={t('reEnterPwd')}
              onChange={(e) => onChangePwdCheck(e)}
              defaultValue={pwdCheck}
            />
          </div>
          {notify.passwordCheck && <p className='join-notify'>*{notify.passwordCheck}</p>}
        </div>
        <div className='join-input-box'>
          <div className='join-input'>
            <div className='phone-code'>
              <DropDown
                defualtValue={'1 (US)'}
                options={phonNumberOptions}
                onChange={(option) => onChangeCountry(option)}
              />
            </div>
            <input
              type='text'
              placeholder={t('enterPhone')}
              onChange={(e) => onChangePhoneNumber(e)}
              value={phoneNumber}
            />
          </div>
          {notify.phoneNumber && <p className='join-notify'>*{notify.phoneNumber}</p>}
        </div>

        <div className='join-input-box'>
          <div className='join-input'>
            <div className='member-type'>
              <DropDown
                defualtValue={'1'}
                options={memberTypeOptions}
                onChange={(option) => onChangeMemberType(option)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='join-btns'>
        <div className='join-cancel' onClick={() => onChangeStepLevel(1)}>
          {t('cancel')}
        </div>

        <div className='join-next' onClick={onClickJoinCheck}>
          {t('next')}
        </div>
      </div>
      <Popup
        modal
        open={isJoinPopup}
        onClose={() => {
          setIsJoinPopup(false);
        }}
      >
        <div className='join-popup'>
          <div className='join-popup-title'>{t('가입 하시겠습니까?')}</div>
          <div className='join-btns'>
            <div
              className='join-cancel'
              onClick={() => {
                setIsJoinPopup(false);
              }}
            >
              {t('cancel')}
            </div>
            <div className='join-next' onClick={onClickJoin}>
              {t('next')}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

const phonNumberOptions = codes.map(function (code) {
  var obj = { value: '', label: '' };
  obj['value'] = `${code.countryCodes[0].toString()} (${code.isoCode2})`;
  obj['label'] = `+${code.countryCodes[0].toString()} (${code.isoCode2})`;
  return obj;
});
