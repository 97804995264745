import { NoticeList } from '../NoticeList/NoticeList';
import './NoticeBox.scss';
import { useTranslation } from 'react-i18next';

type NoticeBoxProps = {
  email?: string;
};

export const NoticeBox = ({ email }: NoticeBoxProps) => {
  const { t } = useTranslation();
  return (
    <div className='NoticeBox'>
      <div className='notice-menu-list'>
        <div className={`notice-menu-item is-selected-menu`}>
          <div className={`notice-menu-img`}>
            <img src={'/images/notice/공지사항_Pressed.png'} alt='IMG' />
          </div>
          <div className='notice-menu-title'>{t('header_notice')}</div>
        </div>
      </div>
      <NoticeList />
    </div>
  );
};
