import './DropDown.scss';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export type DropDownOption = {
  value: string;
  label: string;
};

type DropDownProps = {
  options: DropDownOption[];
  defualtValue: string;
  disabled?: boolean;
  onChange(option: any): void;
};

export const DropDown = ({ options, defualtValue, disabled, onChange }: DropDownProps) => {
  return (
    <div className='DropDown'>
      <Dropdown
        options={options}
        value={defualtValue}
        onChange={(option) => onChange(option)}
        controlClassName='drop-down'
        arrowClosed={<img src='/images/join/1_Normal.png' alt='^' />}
        arrowOpen={<img src='/images/join/1_Pressed.png' alt='^' />}
        disabled={disabled || false}
      />
    </div>
  );
};
