import { Language, minorTopic } from 'libs/types';
import './CmsMinorTopicList.scss';

type CmsMinorTopicListProps = {
  minorTopicList: minorTopic[];
  curMinorTopicId: string;
  onClickMinorTopic(minorTopicId: string): void;
  lang: Language;
};

export const CmsMinorTopicList = ({
  curMinorTopicId,
  minorTopicList,
  lang,
  onClickMinorTopic,
}: CmsMinorTopicListProps) => {
  if (minorTopicList.length === 0) return null;

  return (
    <div className='CmsMinorTopicList'>
      <ul className='minor-topic-list'>
        {minorTopicList.map((minorTopic) => (
          <li
            className={`minor-topic-title ${curMinorTopicId === minorTopic._id ? 'is-selected-topic' : ''}`}
            key={minorTopic._id}
            onClick={() => onClickMinorTopic(minorTopic._id)}
          >
            {minorTopic.title[lang]}
          </li>
        ))}
      </ul>
    </div>
  );
};
