import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import { getCookie } from 'libs/cookie';
import { dateFormat } from 'libs/dateFormat';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './NoticeList.scss';
import { useTranslation } from 'react-i18next';
import { Lang, Language } from 'libs/types';

type noticeInfo = {
  _id: string;
  title: Lang; //제목
  content: Lang; //내용
  noticeType: string; //공지사항 유형 list) 공지사항, 이벤트 등
  regDate: Date; //공지사항 등록 날짜
  modDate: Date; //공지사항 수정 날짜
};

export const NoticeList = () => {
  const { t } = useTranslation();
  const [noticeList, setNoticeList] = useState<noticeInfo[]>([]);
  const [curShowId, setCurShowId] = useState('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const lang = localStorage.getItem('lang') as Language;

  let limit = 5;

  const onLoadNoticeList = async () => {
    const res_notice = await LifeSportsAPI.get('/v1.0/notices', {
      headers: { authorization: 'Bearer ' + getCookie('accessToken') },
    });

    if (res_notice.data.data.noticeList.length > 0) {
      setNoticeList(res_notice.data.data.noticeList);
      setTotalCount(res_notice.data.data.totalCount);
    }
  };

  const onClickNoticePage = async (event: any) => {
    window.scrollTo(0, 0);
    const offset = event.selected * limit;

    const res_lecture = await LifeSportsAPI.get(`/v1.0/notices?&offset=${offset}&limit=${limit}`, {
      headers: {
        authorization: 'Bearer ' + getCookie('accessToken'),
      },
    });

    if (res_lecture.data.data.noticeList.length <= 0) return;

    if (noticeList === res_lecture.data.data.noticeList) return;

    setNoticeList(res_lecture.data.data.noticeList);
    setTotalCount(res_lecture.data.data.totalCount);
  };

  const onClickNotice = (noticeId: string) => {
    if (noticeId === curShowId) return;

    setCurShowId(noticeId);
  };
  useEffect(() => {
    onLoadNoticeList();
  }, []);

  return (
    <div className='NoticeList'>
      <div className='notice-header'>
        <h2 className='notice-header-title'>{t('notice_header')}</h2>
        {/* <div className='notice-write-btn'>작성</div> */}
      </div>

      <div className='notice-list'>
        {noticeList.length > 0 ? (
          noticeList.map((notice, i) => (
            <div
              className={`notice-item ${notice._id === curShowId ? 'is-selected-notice' : ''}`}
              key={notice._id}
              onClick={() => onClickNotice(notice._id)}
            >
              <div className='notice-title-box'>
                <div className='notice-num'>{i + 1}</div>
                <div className='notice-title'>{notice.title[lang]}</div>
                <div className='notice-right'>
                  <div className='notice-date'>{dateFormat(notice.regDate, 'yyyy.MM.dd')}</div>
                  <div className='notice-arrow'>
                    <img
                      src={notice._id === curShowId ? '/images/notice/1_Normal.png' : '/images/notice/1_Pressed.png'}
                      alt='Open'
                    />
                  </div>
                </div>
              </div>
              {notice._id === curShowId && <div className='notice-content'>{notice.content[lang]}</div>}
            </div>
          ))
        ) : (
          <div className='notice-not-found'>
            {t('notice_notfound1')}
            <p>{t('notice_notfound2')}</p>
          </div>
        )}
      </div>
      {noticeList.length > 0 && (
        <div className='notice-pagination'>
          <ReactPaginate
            previousLabel={<img src={'/images/cms/왼쪽 화살표.png'} alt='>' />}
            nextLabel={<img src={'/images/cms/오른쪽 화살표.png'} alt='>' />}
            marginPagesDisplayed={5}
            pageCount={Math.ceil(totalCount / limit)}
            pageClassName='page'
            pageRangeDisplayed={limit}
            onPageChange={onClickNoticePage}
          />
        </div>
      )}
    </div>
  );
};
