import { DropDown, DropDownOption } from 'components/common/DropDown/DropDown';
import './MyInfoBox.scss';
import codes from 'country-calling-code';
import {
  AsYouType,
  CountryCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import React, { useState } from 'react';
import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Language, MemberInfo } from 'libs/types';
import { getCookie } from 'libs/cookie';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

type MyInfoBoxProps = {
  memberInfo: MemberInfo;
};

export const MyInfoBox = ({ memberInfo }: MyInfoBoxProps) => {
  const { t } = useTranslation();
  const [currentPwd, setCurrentPwd] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const [pwdCheck, setPwdCheck] = useState<string>('');
  const [isInfoPopup, setIsInfoPopup] = useState<boolean>(false);
  const history = useHistory();

  const [phoneNumber, setPhoneNumber] = useState<string>(memberInfo.phoneNumber);
  const [memberType, setMemberType] = useState<string>(memberInfo.memberType);
  const [country, setCountry] = useState<CountryCode>('KR');
  const [notify, setNotify] = useState({
    curPassword: '',
    password: '',
    passwordCheck: '',
    phoneNumber: '',
  });
  const lang = localStorage.getItem('lang') as Language;

  const memberTypeOptions: DropDownOption[] = [
    { value: '1', label: t('Learner') },
    { value: '2', label: t('Instructor') },
  ];

  const onChangeCurPwd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPwd(e.target.value);

    if (!isStrongPassword(e.target.value, { minUppercase: 0, minSymbols: 0 })) {
      setNotify({ ...notify, curPassword: t('validPwd') });
    } else {
      setNotify({ ...notify, curPassword: '' });
    }
  };

  const onChangePwd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPwd(e.target.value);

    if (!isStrongPassword(e.target.value, { minUppercase: 0, minSymbols: 0 })) {
      setNotify({ ...notify, password: t('validPwd') });
    } else {
      setNotify({ ...notify, password: '' });
    }
  };
  const onChangePwdCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPwdCheck(e.target.value);

    if (!isStrongPassword(e.target.value, { minUppercase: 0, minSymbols: 0 })) {
      setNotify({ ...notify, passwordCheck: t('validPwd') });
    } else {
      setNotify({ ...notify, passwordCheck: '' });
    }
  };

  const onChangeCountry = (option: DropDownOption) => {
    const codeInfo = codes.filter((code) => `${code.countryCodes[0].toString()} (${code.isoCode2})` === option.value);
    setCountry(codeInfo[0].isoCode2 as CountryCode);
  };

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _phoneNumber = new AsYouType(country).input(e.target.value);
    setPhoneNumber(_phoneNumber);

    if (
      isPossiblePhoneNumber(phoneNumber, country) === true &&
      isValidPhoneNumber(phoneNumber, country) === true &&
      validatePhoneNumberLength(phoneNumber, country) === undefined
    ) {
      setNotify({ ...notify, phoneNumber: '' });
    }
  };

  const onChangeMemberType = (option: DropDownOption) => {
    setMemberType(option.value);
  };

  const onClickInfoCheck = () => {
    if (currentPwd) {
      if (!pwd) {
        setNotify({ ...notify, password: t('enterNewPwd') });
        return;
      }
      if (!pwdCheck) {
        setNotify({ ...notify, passwordCheck: t('reEnterNewPwd') });
        return;
      }
      if (!isStrongPassword(currentPwd, { minUppercase: 0, minSymbols: 0 })) {
        setNotify({ ...notify, curPassword: t('validPwd') });
        return;
      }
      if (!isStrongPassword(pwd, { minUppercase: 0, minSymbols: 0 })) {
        setNotify({ ...notify, password: t('validPwd') });
        return;
      }
      if (!isStrongPassword(pwdCheck, { minUppercase: 0, minSymbols: 0 })) {
        setNotify({ ...notify, passwordCheck: t('validPwd') });
        return;
      }
      if (pwd !== pwdCheck) {
        setNotify({ ...notify, passwordCheck: t('newPwdInvalid') });
        return;
      }
    }
    if (
      isPossiblePhoneNumber(phoneNumber, country) === false ||
      isValidPhoneNumber(phoneNumber, country) === false ||
      validatePhoneNumberLength(phoneNumber, country) !== undefined
    ) {
      setNotify({ ...notify, phoneNumber: t('validPhone') });
      return;
    }

    setIsInfoPopup(true);
  };

  const onClickUpdateInfo = async () => {
    try {
      const accessToken = getCookie('accessToken');

      const res_member = await LifeSportsAPI.patch(
        `/v1.0/members`,
        {
          currentPwd,
          pwd,
          pwdCheck,
          phoneNumber,
          receiveMarketing: false,
          memberType,
        },
        {
          headers: { Authorization: 'Bearer ' + accessToken },
        },
      );
      if (res_member.data.status) {
        setIsInfoPopup(false);
        alert(t('myinfo_accountUpdate'));
        window.location.reload();
      }
    } catch (error: any) {
      if (lang !== 'ko-KR') {
        return alert(error.response.data.message);
      } else {
        return alert(error.response.data.alert_message);
      }
    }
  };

  return (
    <div className='MyInfoBox'>
      <h2 className='myinfo-title'>{t('myinfo_header')}</h2>
      <div className='myinfo-inputs'>
        <div className='myinfo-input-box'>
          <div className='myinfo-input'>
            <input type='text' placeholder={t('enterId')} defaultValue={memberInfo.email} disabled={true} />
          </div>
        </div>
        <div className='myinfo-input-box'>
          <div className='myinfo-input'>
            <input
              type='password'
              maxLength={16}
              placeholder={t('enterCurPwd')}
              onChange={(e) => onChangeCurPwd(e)}
              defaultValue={currentPwd}
            />
          </div>
          <p className='myinfo-notify'>*{t('pwdRule')}</p>
          {notify.curPassword && <p className='myinfo-notify'>*{notify.curPassword}</p>}
        </div>
        <div className='myinfo-input-box'>
          <div className='myinfo-input'>
            <input
              type='password'
              maxLength={16}
              placeholder={t('enterNewPwd')}
              onChange={(e) => onChangePwd(e)}
              defaultValue={pwd}
            />
          </div>
          {notify.password && <p className='myinfo-notify'>*{notify.password}</p>}
        </div>
        <div className='myinfo-input-box'>
          <div className='myinfo-input'>
            <input
              type='password'
              placeholder={t('reEnterNewPwd')}
              onChange={(e) => onChangePwdCheck(e)}
              defaultValue={pwdCheck}
            />
          </div>
          {notify.passwordCheck && <p className='myinfo-notify'>*{notify.passwordCheck}</p>}
        </div>
        <div className='myinfo-input-box'>
          <div className='myinfo-input'>
            <div className='phone-code'>
              <DropDown
                defualtValue={'1 (US)'}
                options={phonNumberOptions}
                onChange={(option) => onChangeCountry(option)}
              />
            </div>
            <input
              type='text'
              placeholder={t('enterPhone')}
              onChange={(e) => onChangePhoneNumber(e)}
              value={phoneNumber}
            />
          </div>
          {notify.phoneNumber && <p className='myinfo-notify'>*{notify.phoneNumber}</p>}
        </div>

        <div className='myinfo-input-box'>
          <div className='myinfo-input'>
            <div className='member-type'>
              <DropDown
                defualtValue={memberInfo.memberType}
                options={memberTypeOptions}
                onChange={(option) => onChangeMemberType(option)}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className='myinfo-btns'>
        <div className='myinfo-cancel' onClick={() => history.goBack()}>
          {t('cancel')}
        </div>

        <div className='myinfo-next' onClick={onClickInfoCheck}>
          {t('next')}
        </div>
      </div>
      <Popup
        modal
        open={isInfoPopup}
        onClose={() => {
          setIsInfoPopup(false);
        }}
      >
        <div className='myinfo-popup'>
          <div className='myinfo-popup-title'>{t('doUWantToUpdate')}</div>
          <div className='myinfo-btns'>
            <div
              className='myinfo-cancel'
              onClick={() => {
                setIsInfoPopup(false);
              }}
            >
              {t('cancel')}
            </div>
            <div className='myinfo-next' onClick={onClickUpdateInfo}>
              {t('next')}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

const phonNumberOptions = codes.map(function (code) {
  var obj = { value: '', label: '' };
  obj['value'] = `${code.countryCodes[0].toString()} (${code.isoCode2})`;
  obj['label'] = `+${code.countryCodes[0].toString()} (${code.isoCode2})`;
  return obj;
});
