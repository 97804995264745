import { Template } from 'components/base/Template/Template';
import { JoinFlexBox } from 'components/join/JoinFlexBox/JoinFlexBox';

export const JoinPage = () => {
  return (
    <Template>
      <JoinFlexBox />
    </Template>
  );
};
