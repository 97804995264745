import { Header } from 'components/base/Header/Header';
import { Template } from 'components/base/Template/Template';
import { CmsMajorTopicList } from 'components/cms/CmsMajorTopicList/CmsMajorTopicList';
import { CmsFlexBox } from 'components/cms/CmsFlexBox/CmsFlexBox';
import useMember from 'hooks/useMember';
import { useCallback, useEffect, useState } from 'react';
import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import { getCookie } from 'libs/cookie';
import useLocationQuery from 'hooks/useLocationQuery';
import { useHistory } from 'react-router';
import useSession from 'hooks/useSession';
import { Language } from 'libs/types';

export const CmsPage = () => {
  const { isSession } = useSession();
  const { curTopicTitle = '' } = useLocationQuery();
  const [majorTopicTitle, setMajorTopicTitle] = useState('');
  const [curMinorTopicId, setCurMinorTopicId] = useState('');
  const history = useHistory();

  const [lectureList, setLectureList] = useState([]);
  const [minorTopicList, setMinorTopicList] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const lang = localStorage.getItem('lang') as Language;

  let limit = 10;
  const screenWidth = window.innerWidth;

  if (screenWidth > 1500) {
    limit = 10;
  } else if (screenWidth > 768) {
    limit = 9;
  } else if (screenWidth > 500) {
    limit = 8;
  } else {
    limit = 6;
  }

  const { email, memberInfo } = useMember();
  const accessToken = getCookie('accessToken');

  const onClickMajorTopic = useCallback(
    async (title: string) => {
      if (title === majorTopicTitle) return;
      history.push('/cms?curTopicTitle=' + title);

      setCurMinorTopicId('');
      setMinorTopicList([]);
      setLectureList([]);
      setTotalCount(0);
      setMajorTopicTitle(title);

      const res_major_topic = await LifeSportsAPI.get(`/v1.0/major-topics?title=${title}&lang=${lang}`, {
        headers: {
          authorization: 'Bearer ' + accessToken,
        },
      });

      if (res_major_topic.data.data.majorTopicList.length <= 0) return;

      const majorTopicId = res_major_topic.data.data.majorTopicList[0]._id;

      const res_minor_topic = await LifeSportsAPI.get(`/v1.0/minor-topics?majorTopicId=${majorTopicId}`, {
        headers: {
          authorization: 'Bearer ' + accessToken,
        },
      });

      const minorTopicList = res_minor_topic.data.data.minorTopicList;
      setMinorTopicList(minorTopicList);
    },
    [accessToken, majorTopicTitle, history, lang],
  );

  const onClickMinorTopic = useCallback(
    async (minorTopicId: string) => {
      if (minorTopicId === curMinorTopicId) return;

      setCurMinorTopicId(minorTopicId);
      setLectureList([]);
      setTotalCount(0);

      const res_lecture = await LifeSportsAPI.get(
        `/v1.0/lectures?minorTopicId=${minorTopicId}&offset=${0}&limit=${limit}`,
        {
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
        },
      );

      if (res_lecture.data.data.lectureList.length <= 0) return;

      if (lectureList === res_lecture.data.data.lectureList) return;

      setLectureList(res_lecture.data.data.lectureList);
      setTotalCount(res_lecture.data.data.totalCount);
    },
    [accessToken, lectureList, curMinorTopicId, limit],
  );

  const onClickLecturePage = async (event: any) => {
    window.scrollTo(0, 0);
    const offset = event.selected * limit;

    const res_lecture = await LifeSportsAPI.get(
      `/v1.0/lectures?minorTopicId=${curMinorTopicId}&offset=${offset}&limit=${limit}`,
      {
        headers: {
          authorization: 'Bearer ' + accessToken,
        },
      },
    );

    if (res_lecture.data.data.lectureList.length <= 0) return;

    if (lectureList === res_lecture.data.data.lectureList) return;

    setLectureList(res_lecture.data.data.lectureList);
    setTotalCount(res_lecture.data.data.totalCount);
  };

  useEffect(() => {
    if (!curTopicTitle) return;

    onClickMajorTopic(curTopicTitle);
  }, [curTopicTitle, onClickMajorTopic]);

  useEffect(() => {
    if (!isSession) history.push('/sign-in');
  }, [isSession, history]);

  useEffect(() => {
    if (!memberInfo) return;
    if (memberInfo?.memberType !== '2') {
      history.push('/');
    }
  }, [memberInfo, history]);

  if (!email) return null;

  return (
    <Template header={<Header />}>
      <CmsMajorTopicList majorTopicTitle={majorTopicTitle} onClickMajorTopic={onClickMajorTopic} />
      <CmsFlexBox
        curMinorTopicId={curMinorTopicId}
        minorTopicList={minorTopicList}
        lectureList={lectureList}
        totalCount={totalCount}
        limit={limit}
        onClickMinorTopic={onClickMinorTopic}
        onClickLecturePage={onClickLecturePage}
        lang={lang}
      />
    </Template>
  );
};
