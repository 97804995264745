import './MyInfoBanner.scss';
import { useTranslation } from 'react-i18next';
type MyInfoBannerProps = {
  stepImgUrl: string;
  stepText: string;
};

export const MyInfoBanner = ({ stepImgUrl, stepText }: MyInfoBannerProps) => {
  const { t } = useTranslation();
  return (
    <div className='MyInfoBanner'>
      <div className='myinfo-banner-bg' style={{ backgroundImage: `url('/images/join/bg.jpg')` }}>
        <div className='myinfo-banner-title'>
          <p>{t('header_title')}</p>
        </div>

        <div className='myinfo-step'>
          <img src={stepImgUrl} alt={'*'} />
          <p>{stepText}</p>
          <div className='step-levels'>
            <div className={`step-level current-step`} />
          </div>
        </div>
      </div>
    </div>
  );
};
