import './JoinBanner.scss';
import { useTranslation } from 'react-i18next';

type JoinBannerProps = {
  stepImgUrl: string;
  stepText: string;
  stepLevel: number;
};

export const JoinBanner = ({ stepImgUrl, stepText, stepLevel }: JoinBannerProps) => {
  const { t } = useTranslation();
  return (
    <div className='JoinBanner'>
      <div className='join-banner-bg' style={{ backgroundImage: `url('/images/join/bg.jpg')` }}>
        <div className='join-banner-title'>
          <p>{t('header_title')}</p>
        </div>

        <div className='join-step'>
          <img src={stepImgUrl} alt={'*'} />
          <p>{stepText}</p>
          <div className='step-levels'>
            <div className={`step-level ${stepLevel === 1 ? 'current-step' : ''}`} />
            <div className={`step-level ${stepLevel === 2 ? 'current-step' : ''}`} />
          </div>
        </div>
      </div>
    </div>
  );
};
