import './LoginBanner.scss';
import { useTranslation } from 'react-i18next';

export const LoginBanner = () => {
  const { t } = useTranslation();

  return (
    <div className='LoginBanner'>
      <div className='login-banner-bg' style={{ backgroundImage: `url('/images/login/bg.jpg')` }}>
        <div className='login-banner-title'>
          <p>{t('login_bannerTitle1')}</p>
          <p>{t('login_bannerTitle2')}</p>
        </div>
      </div>
    </div>
  );
};
