import { useState } from 'react';
import { JoinAgree } from '../JoinAgree/JoinAgree';
import { JoinBanner } from '../JoinBanner/JoinBanner';
import { JoinBox } from '../JoinBox/JoinBox';
import './JoinFlexBox.scss';
import { useTranslation } from 'react-i18next';

export const JoinFlexBox = () => {
  const [stepLevel, setStepLevel] = useState(1);
  const { t } = useTranslation();

  const onChangeStepLevel = (level: number) => {
    setStepLevel(level);
  };

  return (
    <div className='JoinFlexBox'>
      <JoinBanner
        stepLevel={stepLevel}
        stepImgUrl={stepLevel === 1 ? '/images/join/이용약관.png' : '/images/join/회원가입.png'}
        stepText={stepLevel === 1 ? t('join_termHeader') : t('join_header')}
      />
      <JoinAgree stepLevel={stepLevel} onChangeStepLevel={onChangeStepLevel} />
      <JoinBox stepLevel={stepLevel} onChangeStepLevel={onChangeStepLevel} />
    </div>
  );
};
