import { Language, lectureInfo } from 'libs/types';
import './LectureList.scss';
import { useTranslation } from 'react-i18next';

type LectureListProps = {
  lectureList: lectureInfo[];
  lang: Language;
};

export const LectureList = ({ lectureList, lang }: LectureListProps) => {
  const { t } = useTranslation();
  return (
    <div className='LectureList'>
      {lectureList.map((lecture, i) => (
        <div key={i} className='lecture-item'>
          <div className='lecture-title'>
            {i + 1}. {lecture.title[lang]}
          </div>
          <div className='lecture-status'>
            {lecture.isCompleted ? (
              <div className='lecture-completed'>{t('lecture_completedLesson')}</div>
            ) : (
              <div className='lecture-in-progress'>{t('lecture_beforeLesson')}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
