import './MainBanner.scss';
import { useTranslation } from 'react-i18next';

export const MainBanner = () => {
  const { t } = useTranslation();
  return (
    <div className='MainBanner'>
      <div className='main-banner-bg' style={{ backgroundImage: `url(/images/join/bg.jpg)` }}>
        <div className='main-banner-title'>
          {t('main_bannerTitle1')} <h2>{t('main_bannerTitle2')}</h2>
        </div>
      </div>
      <div className='main-banner-box'>
        <div className='main-banner-title'>
          {t('main_bannerTitle1')} <h2>{t('main_bannerTitle2')}</h2>
        </div>
      </div>
    </div>
  );
};
