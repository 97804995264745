import { LoginBanner } from '../LoginBanner/LoginBanner';
import { LoginBox } from '../LoginBox/LoginBox';
import './LoginFlexBox.scss';

export const LoginFlexBox = () => {
  return (
    <div className='LoginFlexBox'>
      <LoginBox />
      <LoginBanner />
    </div>
  );
};
