import './QaBox.scss';
import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { LifeSportsAPI } from 'libs/api/lifesportsApi';
import { getCookie } from 'libs/cookie';
import { useTranslation } from 'react-i18next';
import { Language } from 'libs/types';

type QaBoxProps = {
  email?: string;
};

export const QaBox = ({ email }: QaBoxProps) => {
  const { t } = useTranslation();
  const [isRegistering, setIsRegistering] = useState(false);
  const [inputs, setInputs] = useState({
    title: '',
    content: '',
  });
  const lang = localStorage.getItem('lang') as Language;

  const { title, content } = inputs;

  const onChangeInput = (e: any) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [name]: value, // name 키를 가진 값을 value 로 설정
    });
  };

  const onResetInput = () => {
    setInputs({
      title: '',
      content: '',
    });
    setFiles([]);
    setIsRegistering(false);
  };

  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 3) {
        return alert(t('qna_limitFiles'));
      }

      for (let i = 0; i < acceptedFiles.length; i++) {
        const fileInfo = acceptedFiles[i];

        const fileExts = [
          '.hwp',
          '.doc',
          '.docx',
          '.ppt',
          '.pptx',
          '.xls',
          '.xlsx',
          '.txt',
          '.csv',
          '.jpg',
          '.jpeg',
          '.gif',
          '.png',
          '.bmp',
          '.pdf',
        ];

        const _fileLen = fileInfo.name.length;
        const _lastDot = fileInfo.name.lastIndexOf('.');
        const _fileExt = fileInfo.name.substring(_lastDot, _fileLen).toLowerCase();

        if (!fileExts.includes(_fileExt)) {
          return alert(t('qna_fileExtension') + fileExts);
        }

        if (fileInfo.size > 10 * 1024 * 1024) {
          return alert(t('qna_limitFileSize'));
        }
      }
      setFiles(acceptedFiles);
    },
    [t],
  );

  const onDeleteFile = (file: File) => {
    const filteredFiles = files.filter((fileInfo) => file.name !== fileInfo.name);
    setFiles(filteredFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const onClickRegQna = async () => {
    if (!title) {
      return alert(t('qna_enterTitle'));
    } else if (!content) {
      return alert(t('qna_enterContents'));
    }

    let form = new FormData();

    form.append('title', title);
    form.append('content', content);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      form.append('file', file);
    }
    try {
      setIsRegistering(true);

      const res_qna = await LifeSportsAPI.post('/v1.0/qnas', form, {
        headers: {
          authorization: 'Bearer ' + getCookie('accessToken'),
        },
      });

      if (res_qna.data.status) {
        onResetInput();
        if (lang !== 'ko-KR') {
          return alert(res_qna.data.message);
        } else {
          return alert(res_qna.data.alert_message);
        }
      }
    } catch (err: any) {
      setIsRegistering(false);
      if (lang !== 'ko-KR') {
        return alert(err.response.data.message);
      } else {
        return alert(err.response.data.alert_message);
      }
    }
  };

  if (!email) return null;
  return (
    <div className='QaBox'>
      <h3 className='qa-title'>{t('qna_header')}</h3>

      <div className='qa-input-box'>
        <div className='qa-input-label'>{t('qna_user')}</div>
        <div className='qa-input'>
          <input type='text' disabled defaultValue={email} />
        </div>
      </div>
      <div className='qa-input-box'>
        <div className='qa-input-label'>{t('qna_title')}</div>
        <div className='qa-input'>
          <input type='text' placeholder={t('qna_title')} name='title' value={title} onChange={onChangeInput} />
        </div>
      </div>
      <div className='qa-input-box'>
        <div className='qa-input-label'>{t('qna_question')}</div>
        <div className='qa-input'>
          <textarea
            cols={30}
            rows={4}
            maxLength={5000}
            placeholder={t('qna_content')}
            name='content'
            value={content}
            onChange={onChangeInput}
          />
        </div>
      </div>
      <div className='qa-input-box'>
        <div className='qa-input-label'>{t('qna_addFile')}</div>
        <div className='qa-input'>
          <div {...getRootProps({ className: 'dropzone' })}>
            <div className='qa-file-list'>
              {files.length > 0 ? (
                <ul>
                  {files.map((file: any, i) => (
                    <li key={file.path}>
                      <div className='file-info'>
                        {file.path}
                        <div className='file-x' onClick={() => onDeleteFile(file)}>
                          X
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <label className='placeholder'>{t('dragOrSelectFile')}</label>
              )}
            </div>
            <div className='qa-file-input'>
              <input {...getInputProps()} />
              <button type='button' onClick={open}>
                {t('qna_seleteFile')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='qa-btn-box'>
        <div className='qa-btn' onClick={onClickRegQna}>
          {isRegistering ? '문의 등록중...' : t('qna_submit')}
        </div>
      </div>
    </div>
  );
};
