export const getCookie = (name: string) => {
  const value: string = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop().split(';').shift();
};

export const deleteCookie = (name: string) => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  cookieExpire: Date,
  cookiePath?: string,
  cookieDomain?: string,
  cookieSecure?: string,
) => {
  var cookieText = escape(cookieName) + '=' + escape(cookieValue);
  cookieText += cookieExpire ? '; expires=' + new Date(cookieExpire).toUTCString() : '';
  cookieText += cookiePath ? '; path=' + cookiePath : '';
  cookieText += cookieDomain ? '; domain=' + cookieDomain : '';
  cookieText += cookieSecure ? '; secure' : '';
  document.cookie = cookieText;
};
