import useLocationQuery from 'hooks/useLocationQuery';
import { useHistory } from 'react-router';
import { FaqList } from '../FaqList/FaqList';
import { QaBox } from '../QaBox/QaBox';
import './BoardBox.scss';
import { useTranslation } from 'react-i18next';

type BoardBoxProps = {
  email?: string;
};

export const BoardBox = ({ email }: BoardBoxProps) => {
  const { t } = useTranslation();
  const { type } = useLocationQuery();
  const history = useHistory();

  const menus = [
    {
      title: t('qna_board'),
      normal: '/images/board/Q&A게시판_Normal.png',
      pressed: '/images/board/Q&A게시판_Pressed.png',
      type: 'qa',
    },

    {
      title: 'FAQ',
      normal: '/images/board/FAQ_Normal.png',
      pressed: '/images/board/FAQ_Pressed.png',
      type: 'faq',
    },
  ];

  const onClickMenuType = (_type: string) => {
    if (type === _type) return;

    history.push('/board?type=' + _type);
  };
  return (
    <div className='BoardBox'>
      <div className='board-menu-list'>
        {menus.map((menu, i) => (
          <div
            key={menu.type}
            className={`board-menu-item board-menu-item-${i} ${type === menu.type ? 'is-selected-menu' : ''}`}
            onClick={() => onClickMenuType(menu.type)}
          >
            <div className={`board-menu-img`}>
              <img src={type === menu.type ? menu.pressed : menu.normal} alt='IMG' />
            </div>
            <div className='board-menu-title'>{menu.title}</div>
          </div>
        ))}
      </div>
      {type === 'qa' && <QaBox email={email} />}
      {type === 'faq' && <FaqList />}
    </div>
  );
};
